import { PagedResponse } from '@top-solution/microtecnica-utils';
import { SaleType, SaleTypeSchema } from '../entities/SaleType';
import { api } from './baseApi';

const url = 'v1/sale-types';

const saleTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSaleTypeList: builder.query<{ list: SaleType[]; map: Record<string, SaleType> }, void>({
      query: () => url,
      transformResponse: (data: PagedResponse<SaleType>) => {
        const list = SaleTypeSchema.array().parse(data.data);
        const map: Record<string, SaleType> = {};
        list.forEach((type) => (map[type.id] = type));
        return { list, map };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadSaleTypeListQuery } = saleTypeApi;
