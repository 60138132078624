import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { NotFoundPage, UnauthorizedPage } from '..';
import { UserRole } from '../../entities/User';
import ParcelAddPage from './PacelAddPage';
import ParcelDetailPage from './ParcelDetailPage';
import ParcelEditPage from './ParcelEditPage';
import ParcelListPage from './ParcelListPage';
import ParcelReportPage from './ParcelReportPage';

export default function ParcelRouter(): JSX.Element {
  return (
    <AuthGuard
      authorizeRole={(r) => r === UserRole.READ_PARCELS || r === UserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Routes>
        <Route index element={<ParcelListPage />} />
        <Route path="report" element={<ParcelReportPage />} />
        <Route path="add" element={<ParcelAddPage />} />
        <Route path=":id">
          <Route index element={<ParcelDetailPage />} />
          <Route path="edit" element={<ParcelEditPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthGuard>
  );
}
