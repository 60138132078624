import React, { useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import { StatusEnum } from '../../entities/Status';
import { ParcelStatusesByMonthType } from '../../pages/Parcel/ParcelReportPage';
import { useReadStatusListQuery } from '../../services/statusApi';
import { ReportBarChart, ReportBarChartColors } from './ReportBarChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ParcelStatusChartProps {
  data: ParcelStatusesByMonthType[];
  title: string;
}

function ParcelStatusChart(props: ParcelStatusChartProps) {
  const { data, title } = props;
  const { data: statusData, isLoading } = useReadStatusListQuery();

  const labels = data.map((d) => format(parseISO(d.date), 'LLLL', { locale: it }));

  const chartData = useMemo(() => {
    const statusMap: Record<string, number> = {};
    statusData?.list.forEach((status) => (statusMap[status.name] = status.id));
    return {
      labels,
      datasets: [
        {
          label: 'TI aperte',
          data: data.map((d) => d.values.find((v) => v.status === statusMap[StatusEnum.Open])?.ti),
          backgroundColor: ReportBarChartColors[0],
        },
        {
          label: 'TI chiuse',
          data: data.map((d) => d.values.find((v) => v.status === statusMap[StatusEnum.Close])?.ti),
          backgroundColor: ReportBarChartColors[1],
        },
        {
          label: 'TI scarico parziale',
          data: data.map((d) => d.values.find((v) => v.status === statusMap[StatusEnum.PartialDischarge])?.ti),
          backgroundColor: ReportBarChartColors[2],
        },
        {
          label: 'TE aperte',
          data: data.map((d) => d.values.find((v) => v.status === statusMap[StatusEnum.Open])?.te),
          backgroundColor: ReportBarChartColors[3],
        },
        {
          label: 'TE chiuse',
          data: data.map((d) => d.values.find((v) => v.status === statusMap[StatusEnum.Close])?.te),
          backgroundColor: ReportBarChartColors[4],
        },
        {
          label: 'TE scarico parziale',
          data: data.map((d) => d.values.find((v) => v.status === statusMap[StatusEnum.PartialDischarge])?.te),
          backgroundColor: ReportBarChartColors[5],
        },
      ],
    };
  }, [data, labels, statusData?.list]);

  if (isLoading) {
    return null;
  }

  return <ReportBarChart title={title} chartData={chartData} loading={isLoading} />;
}

export default React.memo(ParcelStatusChart);
