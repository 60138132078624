import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { NumericInput } from '@top-solution/microtecnica-mui';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { FormRow, CurrencySelect } from '../../components/Form';
import { TEFormSchema, TEForm, Item, validateUniquePnSn } from '../../entities/Item';
import { formatISODate } from '../../utils';

type TemporaryExportDialogProps = Omit<DialogProps, 'onClose'> & {
  editMode?: boolean;
  initialValues: TEForm;
  onClose: (item?: Item) => void;
  otherItems: Item[];
};

export function TemporaryExportDialog(props: TemporaryExportDialogProps): JSX.Element {
  const { initialValues, editMode, onClose, otherItems, ...dialogProps } = props;

  const { control, handleSubmit } = useForm<TEForm>({
    defaultValues: initialValues,
    resolver: zodResolver(TEFormSchema.superRefine((data, ctx) => validateUniquePnSn(data, ctx, otherItems))),
  });

  function onSubmit(data: TEForm) {
    onClose({
      ...data,
      dutiesCountervalue: data.dutiesCountervalue ?? undefined,
      customsDutiesCountervalue: data.customsDutiesCountervalue ?? undefined,
      awbDate: data.awbDate ? formatISODate(data.awbDate) : undefined,
      billImAReg6Date: data.billImAReg6Date ? formatISODate(data.billImAReg6Date) : undefined,
    });
  }

  return (
    <Dialog fullWidth maxWidth="md" {...dialogProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{editMode ? 'Modifica item' : 'Nuovo item'}</DialogTitle>
        <DialogContent>
          <FormRow sx={{ marginTop: 1 }}>
            <Controller
              control={control}
              name="pn"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="P/N"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="sn"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="S/N"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="hts"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="HTS"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="weight"
              render={({ field, fieldState: { error } }) => (
                <NumericInput
                  sx={{ flex: 1 }}
                  options={{ minimumFractionDigits: 1 }}
                  label="Peso netto"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow sx={{ marginTop: 1 }}>
            <Controller
              control={control}
              name="value"
              render={({ field, fieldState: { error } }) => (
                <NumericInput
                  sx={{ flex: 1 }}
                  options={{ minimumFractionDigits: 2 }}
                  label="Valore"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="currency"
              render={({ field, fieldState: { error } }) => (
                <CurrencySelect
                  sx={{ width: 100 }}
                  label="Valuta"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="countervalue"
              render={({ field, fieldState: { error } }) => (
                <NumericInput
                  sx={{ flex: 1 }}
                  options={{ minimumFractionDigits: 2 }}
                  label="Controvalore (€)"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="boxNumber"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="N. Box"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="awb"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="AWB"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="awbDate"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Data AWB"
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                      helperText: error?.message ?? ' ',
                      sx: { flex: 1 },
                    },
                  }}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="billImAReg6"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="Bolla IM A Reg. 6"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="billImAReg6Date"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Data Bolla IM A Reg. 6"
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                      helperText: error?.message ?? ' ',
                      sx: { flex: 1 },
                    },
                  }}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="purchaseOrder"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ flex: 1 }}
                  label="P.O."
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="customsDutiesCountervalue"
              render={({ field, fieldState: { error } }) => (
                <NumericInput
                  sx={{ flex: 1 }}
                  options={{ minimumFractionDigits: 2 }}
                  label="Diritti doganali (€)"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="dutiesCountervalue"
              render={({ field, fieldState: { error } }) => (
                <NumericInput
                  sx={{ flex: 1 }}
                  options={{ minimumFractionDigits: 2 }}
                  label="Dazi (€)"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
          </FormRow>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Conferma
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
