import { z } from 'zod';

export enum ReportCountervaluesEnum {
  CustomsCountervalue = 'customsDutiesCountervalue',
  DutiesCountervalue = 'dutiesCountervalue',
  Countervalue = 'countervalue',
}

const ValueByCurrencySchema = z.object({
  currency: z.string(),
  value: z.number(),
});

const QuantityByStatusSchema = z.object({
  status: z.number(),
  value: z.number(),
});

const QuantityByTypeSchema = z.object({
  type: z.number(),
  value: z.number(),
});

const TotalCounterValuesSchema = z.object({
  countervalue: z.number(),
  customsDutiesCountervalue: z.number(),
  dutiesCountervalue: z.number(),
});

const ItemReportSchema = z.object({
  totalValues: z.array(ValueByCurrencySchema),
  totalParcels: z.array(QuantityByStatusSchema).min(1),
  totalCountervalues: TotalCounterValuesSchema,
});

export const ParcelReportSchema = z.object({
  date: z.string(),
  ti: ItemReportSchema,
  te: ItemReportSchema,
});

export const ParcelHtsReportSchema = z.object({
  hts: z.string(),
  closedItemsQuantity: z.number(),
  openItemsQuantity: z.number(),
  closedItemsCountervalue: z.number(),
  openItemsCountervalue: z.number(),
});

export const PaperworkReportSchema = z.object({
  date: z.string(),
  paperworksByStatus: z.array(QuantityByStatusSchema),
  paperworksByType: z.array(QuantityByTypeSchema),
});

export type ValueByCurrency = z.infer<typeof ValueByCurrencySchema>;
export type ParcelReport = z.infer<typeof ParcelReportSchema>;
export type ParcelHtsReport = z.infer<typeof ParcelHtsReportSchema>;
export type PaperworkReport = z.infer<typeof PaperworkReportSchema>;
