import { useCallback } from 'react';
import { Paperwork } from '../entities/Paperwork';
import { ParcelPaperwork } from '../entities/Parcel';
import { useReadPaperworkStatusListQuery } from '../services/paperworkStatusApi';

export function usePaperworkStatus() {
  const { data } = useReadPaperworkStatusListQuery();

  const getPaperworkStatus = useCallback(
    (paper: Paperwork | ParcelPaperwork | { status: number }) => {
      return data?.list.find((s) => s.id === paper.status)?.name;
    },
    [data?.list],
  );

  return { getPaperworkStatus };
}
