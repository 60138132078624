import { useCallback, useMemo } from 'react';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';

import { useReadCurrencyListQuery } from '../services/currencyApi';

export function useCurrencyColDef(): GridColDef {
  const { data } = useReadCurrencyListQuery();

  const valueOptions = useMemo(() => data?.list.map((c) => ({ value: c.code, label: c.code })), [data]);
  const valueFormatter = useCallback(
    ({ value }: GridValueFormatterParams) =>
      typeof value === 'string' ? data?.map[value]?.code || `⚠️ ${value}` : value,
    [data],
  );
  return {
    field: 'currency',
    headerName: 'Valuta',
    type: 'singleSelect',
    valueOptions,
    valueFormatter,
  };
}
