import React, { useCallback, useState } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

type DialogOnClose = (
  event: React.UIEvent,
  reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClick' | 'submitClick',
) => void;

interface ConfirmAssigneeDialogProps extends Omit<DialogProps, 'onClose' | 'title'> {
  title: React.ReactNode | React.ReactNode[];
  description?: React.ReactNode | React.ReactNode[];
  confirmText?: string;
  confirmButtonText?: string;
  error?: ApiError | Error;
  confirmColor?: ButtonProps['color'];
  inProgress?: boolean;
  onConfirm: (note: string) => void;
  onClose: DialogOnClose;
}

function ConfirmAssigneeDialogComponent(props: ConfirmAssigneeDialogProps): JSX.Element {
  const {
    title,
    description,
    error,
    inProgress,
    onClose,
    onConfirm,
    open,
    confirmButtonText,
    confirmColor,
    ...dialogProps
  } = props;
  const [assigneeNote, setAssigneeNote] = useState('');

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      onConfirm(assigneeNote);
    },
    [assigneeNote, onConfirm],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...dialogProps}
      sx={{
        '[role="alert"]': {
          mt: 2,
        },
        code: {
          border: '1px solid currentColor',
          borderRadius: 1,
          paddingY: 0.25,
          paddingX: 0.5,
          marginX: '2px',
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {description}
          <TextField
            multiline
            label={'Note'}
            fullWidth
            autoFocus
            onChange={(event) => setAssigneeNote(event.target.value)}
            sx={{ mt: 4 }}
          />
          {error && <ErrorAlert error={error} />}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={(e) => onClose(e, 'cancelClick')}>
            Annulla
          </Button>
          {error ? null : (
            <LoadingButton
              type="submit"
              disabled={assigneeNote === ''}
              loading={inProgress}
              variant="contained"
              color={confirmColor || 'primary'}
            >
              {confirmButtonText || 'Ok'}
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const ConfirmAssigneeDialog = React.memo(ConfirmAssigneeDialogComponent);
