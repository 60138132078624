import { useCallback } from 'react';
import { Parcel } from '../entities/Parcel';
import { useReadStatusListQuery } from '../services/statusApi';

export function useParcelStatus() {
  const { data } = useReadStatusListQuery();

  const getParcelStatus = useCallback(
    (parcel: Parcel) => {
      return data?.list.find((s) => s.id === parcel.status)?.name;
    },
    [data?.list],
  );

  return { getParcelStatus };
}
