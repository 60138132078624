import React from 'react';
import { Link } from 'react-router-dom';
import { useReadPlantsQuery, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpirationFormatted from '../../components/ExpirationFormatted';
import { WideTooltip } from '../../components/WideTooltip';
import { Parcel } from '../../entities/Parcel';
import { useReadSaleTypeListQuery } from '../../services/saleTypeApi';
import { useReadShipmentReasonListQuery } from '../../services/shipmentReasonApi';
import { useReadStatusListQuery } from '../../services/statusApi';
import { formatStringDate } from '../../utils';
import { ParcelDetailItem } from '../Parcel/ParcelDetailPage';

interface ParcelLinkButtonProps {
  parcel: Omit<Parcel, 'attachments' | 'items'>;
}

function ParcelLinkButtonComponent(props: ParcelLinkButtonProps) {
  const { parcel } = props;
  const { data: saleTypeData } = useReadSaleTypeListQuery();
  const { data: shipmentReasonData } = useReadShipmentReasonListQuery();
  const { data: statusData } = useReadStatusListQuery();
  const { data: plantData } = useReadPlantsQuery();
  const { data: countryData } = useReadCountriesQuery();
  return (
    <WideTooltip
      arrow
      title={
        <>
          <ParcelDetailItem label={'Nome Utente'} value={parcel.createUser} />
          <ParcelDetailItem label={'Data Pratica'} value={formatStringDate(parcel.createDatetime)} />
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ fontWeight: 700 }} mr={1}>
              {'Data Scadenza'}:
            </Typography>
            <ExpirationFormatted parcel={parcel as Parcel} typographyOptions={{ variant: 'body1' }} />
          </Box>
          <ParcelDetailItem label={'Stato Pratica'} value={statusData?.map[parcel.status].name} />
          {parcel.assignUser && (
            <>
              <ParcelDetailItem label={'Utente presa in carico'} value={parcel.assignUser} />
              <ParcelDetailItem
                label={'Data presa in carico'}
                value={formatStringDate(parcel.assignDatetime as string)}
              />
            </>
          )}
          <ParcelDetailItem label={'Tipologia Pratica'} value={parcel.regime} />
          <ParcelDetailItem label={'Tipologia Vendita'} value={saleTypeData?.map[parcel.saleType].name} />
          {parcel.militaryLicense && (
            <>
              <ParcelDetailItem label={'Rif. Licenza Militare'} value={parcel.militaryLicense} />
              <ParcelDetailItem
                label={'Data Rilascio Licenza'}
                value={formatStringDate(parcel.militaryLicenseIssueDate as string)}
              />
              <ParcelDetailItem
                label={'Data scadenza'}
                value={formatStringDate(parcel.militaryLicenseExpirationDate as string)}
              />
            </>
          )}
          <ParcelDetailItem label={'Stabilimento'} value={plantData?.map[parcel.plantId].name} />
          <ParcelDetailItem label={'Protocollo Dogana'} value={parcel.customsReferenceNo} />
          <ParcelDetailItem label={'Data Protocollo Dogana'} value={formatStringDate(parcel.customsReferenceNoDate)} />

          <ParcelDetailItem label={'Tipologia Fornitura'} value={shipmentReasonData?.map[parcel.shipmentReason].name} />
          <ParcelDetailItem label={'Cliente/Fornitore'} value={parcel.customerSupplier} />
          <ParcelDetailItem label={'Paese'} value={countryData?.byISO[parcel.country]?.name || parcel.country} />
        </>
      }
      sx={{
        background: 'red',
        '.MuiTooltip-tooltip': {
          background: 'red',
          maxWidth: 500,
        },
      }}
    >
      <Button
        size="small"
        color="primary"
        variant="outlined"
        component={Link}
        to={`/parcel/${parcel.id}`}
        sx={{ mr: 0.5 }}
      >
        {`#${parcel.id}`}
      </Button>
    </WideTooltip>
  );
}

export const ParcelLinkButton = React.memo(ParcelLinkButtonComponent);
