import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CountryAutocomplete } from '@top-solution/microtecnica-mui';
import { useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import Box, { BoxProps } from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { FormRow } from '../../components/Form';
import AttachmentsField from '../../components/Form/AttachmentsField';
import { GenericSelect } from '../../components/Form/GenericSelect';
import { ParcelAddForm, RegimeEnum } from '../../entities/Parcel';
import { SaleTypeEnum } from '../../entities/SaleType';
import { StatusEnum } from '../../entities/Status';
import { useReadSaleTypeListQuery } from '../../services/saleTypeApi';
import { useReadShipmentReasonListQuery } from '../../services/shipmentReasonApi';
import { useReadStatusListQuery } from '../../services/statusApi';
import ItemsGrid from './ItemsGrid';

type ParcelEditFormProps = BoxProps & {
  form: UseFormReturn<ParcelAddForm>;
  editing: boolean;
};

export default function ParcelEditForm(props: ParcelEditFormProps): JSX.Element {
  const { form, editing, ...boxProps } = props;

  const { control, setValue, watch } = form;
  const saleType = watch('saleType');
  const regime = watch('regime');

  const saleTypeList = useReadSaleTypeListQuery();
  const shipmentReasonList = useReadShipmentReasonListQuery();
  const statusList = useReadStatusListQuery();
  const plantList = useReadPlantsQuery();

  return (
    <Box {...boxProps}>
      <FormRow>
        <Controller
          control={control}
          name="customerSupplier"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ flex: 1 }}
              label="Cliente/Fornitore"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <CountryAutocomplete
              required
              sx={{ width: 250 }}
              label="Paese"
              onChange={(_, value) => onChange(value)}
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="plantId"
          render={({ field, fieldState: { error } }) => (
            <GenericSelect
              {...field}
              label="Stabilimento"
              list={plantList.data?.list}
              disabled={plantList.isFetching}
              error={Boolean(error ?? plantList.error)}
              helperText={error?.message ?? ' '}
              sx={{ width: 250 }}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="status"
          render={({ field, fieldState: { error } }) => (
            <GenericSelect
              {...field}
              label="Stato pratica"
              list={statusList.data?.list.filter(({ name }) => name !== StatusEnum.Close) ?? []}
              disabled={statusList.isFetching}
              error={Boolean(error ?? statusList.error)}
              helperText={error?.message ?? ' '}
              sx={{ flex: 1 }}
            />
          )}
        />
        <Controller
          control={control}
          name="deadlineDate"
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              slotProps={{
                textField: {
                  helperText: error?.message ?? ' ',
                  error: Boolean(error),
                  sx: { width: 250 },
                },
              }}
              label="Data Scadenza"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="customsReferenceNo"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Protocollo doganale"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              sx={{ flex: 1 }}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="customsReferenceNoDate"
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              slotProps={{
                textField: {
                  helperText: error?.message ?? ' ',
                  error: Boolean(error),
                  sx: { width: 250 },
                },
              }}
              label="Data Protocollo doganale"
              {...field}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="regime"
          render={({ field, field: { onChange, ref }, fieldState: { error } }) => (
            <TextField
              {...field}
              label={'Tipologia Pratica'}
              onChange={(v) => {
                setValue('items', []);
                onChange(v);
              }}
              select
              SelectProps={{ ref }}
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              fullWidth
              disabled={editing}
              sx={{ flex: 1 }}
            >
              <MenuItem value={RegimeEnum.TI}>T.I.</MenuItem>
              <MenuItem value={RegimeEnum.TE}>T.E.</MenuItem>
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="saleType"
          render={({ field, fieldState: { error } }) => (
            <GenericSelect
              {...field}
              label="Tipologia vendita"
              list={saleTypeList.data?.list}
              disabled={saleTypeList.isFetching}
              error={Boolean(error ?? saleTypeList.error)}
              helperText={error?.message ?? ' '}
              sx={{ flex: 1 }}
            />
          )}
        />

        <Controller
          control={control}
          name="shipmentReason"
          render={({ field, fieldState: { error } }) => (
            <GenericSelect
              {...field}
              label="Tipologia fornitura"
              list={shipmentReasonList.data?.list}
              disabled={shipmentReasonList.isFetching}
              error={Boolean(error ?? shipmentReasonList.error)}
              helperText={error?.message ?? ' '}
              sx={{ flex: 1 }}
            />
          )}
        />
      </FormRow>
      {saleType &&
        (SaleTypeEnum.Military === saleTypeList.data?.map[saleType].name ||
          SaleTypeEnum.DualUse === saleTypeList.data?.map[saleType].name) && (
          <FormRow>
            <Controller
              control={control}
              name="militaryLicense"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Riferimento Licenza Militare"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flex: 1 }}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="militaryLicenseIssueDate"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  slotProps={{
                    textField: {
                      helperText: error?.message ?? ' ',
                      error: Boolean(error),
                    },
                  }}
                  label="Data Rilascio Licenza"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="militaryLicenseExpirationDate"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  slotProps={{
                    textField: {
                      helperText: error?.message ?? ' ',
                      error: Boolean(error),
                    },
                  }}
                  label="Data Scadenza Licenza"
                  {...field}
                />
              )}
            />
          </FormRow>
        )}
      <FormRow>
        <Controller
          control={control}
          name="note"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: '100%' }}
              label="Note"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              multiline
              rows={4}
              {...field}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="attachments"
          render={({ field, fieldState: { error } }) => (
            <AttachmentsField
              {...field}
              value={field.value ?? []}
              sx={{ flex: 1 }}
              label="Allegati"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
            />
          )}
        />
      </FormRow>

      {regime && (
        <FormRow>
          <Controller
            control={control}
            name="items"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <ItemsGrid regime={regime} value={value} onChange={onChange} error={error} />
            )}
          />
        </FormRow>
      )}
    </Box>
  );
}
