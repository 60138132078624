import React, { useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import { ReportCountervaluesEnum } from '../../entities/Report';
import { ParcelCountervalueTotalsByMonthType } from '../../pages/Parcel/ParcelReportPage';
import { ReportBarChart, ReportBarChartColors } from './ReportBarChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ParcelTotalChartProps {
  data: ParcelCountervalueTotalsByMonthType[];
  title: string;
}

function ParcelCounterTotalChart(props: ParcelTotalChartProps) {
  const { data, title } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const labels = data.map((d) => format(parseISO(d.date), 'LLLL', { locale: it }));

  const chartData = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: 'Controvalore (€) TI',
          data: data.map((v) => v.totals.find((t) => t.total === ReportCountervaluesEnum.Countervalue)?.ti),
          backgroundColor: ReportBarChartColors[0],
        },
        {
          label: 'Controvalore (€) Dogana TI',
          data: data.map((v) => v.totals.find((t) => t.total === ReportCountervaluesEnum.CustomsCountervalue)?.ti),
          backgroundColor: ReportBarChartColors[1],
        },
        {
          label: 'Controvalore (€) Dazi TI',
          data: data.map((v) => v.totals.find((t) => t.total === ReportCountervaluesEnum.DutiesCountervalue)?.ti),
          backgroundColor: ReportBarChartColors[2],
        },
        {
          label: 'Controvalore (€) TE',
          data: data.map((v) => v.totals.find((t) => t.total === ReportCountervaluesEnum.Countervalue)?.te),
          backgroundColor: ReportBarChartColors[3],
        },
        {
          label: 'Controvalore (€) Dogana TE',
          data: data.map((v) => v.totals.find((t) => t.total === ReportCountervaluesEnum.CustomsCountervalue)?.te),
          backgroundColor: ReportBarChartColors[4],
        },
        {
          label: 'Controvalore (€) Dazi TE',
          data: data.map((v) => v.totals.find((t) => t.total === ReportCountervaluesEnum.DutiesCountervalue)?.te),
          backgroundColor: ReportBarChartColors[5],
        },
      ],
    };
  }, [data, labels]);

  return <ReportBarChart title={title} chartData={chartData} loading={false} />;
}

export default React.memo(ParcelCounterTotalChart);
