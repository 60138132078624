import { GridColDef } from '@mui/x-data-grid-premium';
import { RegimeEnum } from '../entities/Parcel';

const valueOptions = [
  { value: RegimeEnum.TI, label: 'T.I.' },
  { value: RegimeEnum.TE, label: 'T.E.' },
];

export function useRegimeColDef(): GridColDef {
  return {
    field: 'regime',
    headerName: 'Regime',
    type: 'singleSelect',
    valueOptions,
  };
}
