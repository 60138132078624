import { useCallback, useMemo } from 'react';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';

import { useReadShipmentReasonListQuery } from '../services/shipmentReasonApi';

export function useShipmentReasonColDef(): GridColDef {
  const { data } = useReadShipmentReasonListQuery();

  const valueOptions = useMemo(() => data?.list.map((plant) => ({ value: plant.id, label: plant.name })), [data]);
  const valueFormatter = useCallback(
    ({ value }: GridValueFormatterParams) =>
      typeof value === 'number' ? data?.map[value]?.name || `⚠️ ${value}` : value,
    [data],
  );
  return {
    field: 'shipmentReason',
    headerName: 'Tipologia Fornitura',
    type: 'singleSelect',
    valueOptions,
    valueFormatter,
  };
}
