import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { ParcelAddForm } from '../../entities/Parcel';
import { SaleTypeEnum } from '../../entities/SaleType';
import { UserRole } from '../../entities/User';
import { useParcelSchemaWIthMilitaryCheck } from '../../hooks/useParcelSchemaWIthMilitaryCheck';
import { useReadParcelQuery, useUpdateParcelMutation } from '../../services/parcelApi';
import { useReadSaleTypeListQuery } from '../../services/saleTypeApi';
import { formatISODate, parseISODate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { ParcelSection } from '../sections';
import { ParcelEditCard } from './ParcelEditCard';

const title = 'Modifica pratica';
const breadcrumbs = [{ title: ParcelSection.title, url: ParcelSection.url }];

export default function ParcelEditPage(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams<'id'>();
  const parcelId = params.id ? decodeURIComponent(params.id) : '';
  const parcel = useReadParcelQuery(parcelId);
  const [update, updateStatus] = useUpdateParcelMutation();
  const { ParcelAddFormSchemaWithMilitaryCheck, isLoading } = useParcelSchemaWIthMilitaryCheck();
  const saleType = useReadSaleTypeListQuery();

  const defaultValues = useMemo(
    () =>
      parcel.data
        ? ({
            ...parcel.data,
            customsReferenceNoDate: parseISODate(parcel.data.customsReferenceNoDate),
            deadlineDate: parseISODate(parcel.data.deadlineDate),
            militaryLicenseExpirationDate: parcel.data.militaryLicenseExpirationDate
              ? parseISODate(parcel.data.militaryLicenseExpirationDate)
              : null,
            militaryLicenseIssueDate: parcel.data.militaryLicenseIssueDate
              ? parseISODate(parcel.data.militaryLicenseIssueDate)
              : null,
          } as unknown as ParcelAddForm)
        : undefined,
    [parcel],
  );

  const handleSubmit = useCallback(
    async (data: ParcelAddForm) => {
      const isCivil = SaleTypeEnum.Civil === saleType.data?.map[data.saleType].name;
      await update({
        ...data,
        id: parseInt(parcelId),
        attachments: data.attachments.map((a) => a.id),
        customsReferenceNoDate: formatISODate(data.customsReferenceNoDate),
        deadlineDate: formatISODate(data.deadlineDate),
        militaryLicense: !isCivil ? data.militaryLicense : '',
        militaryLicenseExpirationDate:
          data.militaryLicenseExpirationDate && !isCivil
            ? formatISODate(data.militaryLicenseExpirationDate)
            : undefined,
        militaryLicenseIssueDate:
          data.militaryLicenseIssueDate && !isCivil ? formatISODate(data.militaryLicenseIssueDate) : undefined,
      }).unwrap();
      navigate('..');
    },
    [navigate, parcelId, saleType.data?.map, update],
  );

  return (
    <AuthGuard
      authorizeRole={(role) => role === UserRole.WRITE_PARCELS || role === UserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Layout
        breadcrumbs={[...breadcrumbs, { title: parcelId, url: `${ParcelSection.url}/${parcelId}` }, { title }]}
        disableGutters
        sx={{ p: 1 }}
        error={parcel.error ?? saleType.error}
        inProgress={parcel.isFetching ?? isLoading ?? saleType.isFetching}
        title={`${title} ${parcelId}`}
      >
        {defaultValues && (
          <ParcelEditCard
            title={`${title} ${parcelId}`}
            resolver={ParcelAddFormSchemaWithMilitaryCheck}
            defaultValues={defaultValues}
            isLoading={updateStatus.isLoading}
            error={updateStatus.error}
            onSubmit={handleSubmit}
            editing={true}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
