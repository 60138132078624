import React, { useState } from 'react';
import { FilterOperator, PagedRequestFilter } from '@top-solution/microtecnica-utils';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CancelIcon, ChevronDownIcon } from '../../components/Icons';

interface ParcelAccordionProps {
  onFilter: (value: PagedRequestFilter[]) => void;
  onAccordionChange: (value: boolean) => void;
}

export enum FilterFields {
  pn = 'pn',
  sn = 'sn',
  hts = 'hts',
  mrn = 'mrn',
  customsBill = 'customsBill',
}

export default function ParcelAccordion(props: ParcelAccordionProps): JSX.Element {
  const [filtersMap, setFiltersMap] = useState<Record<FilterFields, string>>({
    pn: '',
    sn: '',
    hts: '',
    mrn: '',
    customsBill: '',
  });

  const handleChange = (prop: FilterFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltersMap({ ...filtersMap, [prop]: event.target.value });
  };

  const handleSearch = () => {
    const filterArray: PagedRequestFilter[] = [];
    for (const [key, value] of Object.entries(filtersMap)) {
      if (value) {
        filterArray.push({ field: key, value, operator: FilterOperator.equals });
      }
    }

    props.onFilter(filterArray);
  };

  return (
    <Accordion
      defaultExpanded
      onChange={(event: React.SyntheticEvent, expanded: boolean) => props.onAccordionChange(expanded)}
    >
      <AccordionSummary expandIcon={<ChevronDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>{'Filtra per Item'}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            '> div': {
              flex: 1,
            },
          }}
        >
          <TextField
            label="P/N"
            sx={{
              '&:hover button': {
                visibility: 'visible',
              },
            }}
            size="small"
            value={filtersMap.pn}
            onChange={handleChange(FilterFields.pn)}
            InputProps={{
              endAdornment: filtersMap.pn && (
                <IconButton
                  sx={{ visibility: 'hidden' }}
                  onClick={() => setFiltersMap({ ...filtersMap, [FilterFields.pn]: '' })}
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
          <TextField
            label="S/N"
            sx={{
              ml: 2,
              '&:hover button': {
                visibility: 'visible',
              },
            }}
            size="small"
            value={filtersMap.sn}
            onChange={handleChange(FilterFields.sn)}
            InputProps={{
              endAdornment: filtersMap.sn && (
                <IconButton
                  sx={{ visibility: 'hidden' }}
                  onClick={() => setFiltersMap({ ...filtersMap, [FilterFields.sn]: '' })}
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
          }}
        >
          <TextField
            label="HTS"
            size="small"
            sx={{
              flex: 1,
              '&:hover button': {
                visibility: 'visible',
              },
            }}
            value={filtersMap.hts}
            onChange={handleChange(FilterFields.hts)}
            InputProps={{
              endAdornment: filtersMap.hts && (
                <IconButton
                  sx={{ visibility: 'hidden' }}
                  onClick={() => setFiltersMap({ ...filtersMap, [FilterFields.hts]: '' })}
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
          <TextField
            label="MRN"
            size="small"
            sx={{
              flex: 1,
              mx: 2,
              '&:hover button': {
                visibility: 'visible',
              },
            }}
            value={filtersMap.mrn}
            onChange={handleChange(FilterFields.mrn)}
            InputProps={{
              endAdornment: filtersMap.mrn && (
                <IconButton
                  sx={{ visibility: 'hidden' }}
                  onClick={() => setFiltersMap({ ...filtersMap, [FilterFields.mrn]: '' })}
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
          <TextField
            label="Bolla"
            size="small"
            sx={{
              flex: 1,
              '&:hover button': {
                visibility: 'visible',
              },
            }}
            value={filtersMap.customsBill}
            onChange={handleChange(FilterFields.customsBill)}
            InputProps={{
              endAdornment: filtersMap.customsBill && (
                <IconButton
                  sx={{ visibility: 'hidden' }}
                  onClick={() => setFiltersMap({ ...filtersMap, [FilterFields.customsBill]: '' })}
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 4,
            alignSelf: 'flex-end',
          }}
        >
          <Button variant="contained" onClick={handleSearch} size="small">
            Cerca
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
