import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterOperator, useReadCountriesQuery, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { format } from 'date-fns';
import jsonexport from 'jsonexport/dist';
import Button from '@mui/material/Button';
import { TrayArrowDownIcon } from '../../components/Icons';
import { useReadParcelListQuery } from '../../services/parcelApi';
import { useReadSaleTypeListQuery } from '../../services/saleTypeApi';
import { useReadShipmentReasonListQuery } from '../../services/shipmentReasonApi';
import { useReadStatusListQuery } from '../../services/statusApi';
import { formatStringDate, numberUtils } from '../../utils';

interface ParcelExportButtonProps {
  sort: string[];
  filters: {
    field: string;
    operator: FilterOperator;
    value: string;
  }[];
}

const headerMapObj = {
  customerSupplier: 'Cliente/Fornitore',
  country: 'Paese',
  createDatetime: 'Data Pratica',
  createUser: 'Nome Utente',
  customsReferenceNo: 'Protocollo Dogana',
  customsReferenceNoDate: 'Data Protocollo Dogana',
  deadlineDate: 'Data Scadenza',
  id: 'Nr Pratica',
  militaryLicense: 'Riferimento Licenza Militare',
  militaryLicenseExpirationDate: 'Data scadenza Licenza Militare',
  militaryLicenseIssueDate: 'Data rilascio Licenza Militare',
  note: 'Note',
  plantId: 'Stabilimento',
  regime: 'Regime',
  status: 'Stato Pratica',
  saleType: 'Tipologia Vendita',
  shipmentReason: 'Tipologia Fornitura',
  pn: 'P/N',
  sn: 'S/N',
  hts: 'HTS',
  weight: 'Peso Netto',
  customsDutiesValue: 'Valore Doganale',
  customsDutiesCountervalue: 'Controvalore Doganale (€)',
  customsDutiesCurrency: 'Valuta Doganale',
  dutiesValue: 'Dazi',
  dutiesCountervalue: 'Controvalore Dazi (€)',
  dutiesCurrency: 'Valuta Dazi',
  value: 'Valore',
  countervalue: 'Controvalore (€)',
  currency: 'Valuta',
  incoterms: 'Incoterms',
  exAReg3: 'C.I. / Fattura',
  exAReg3Date: 'Data C.I. / Fattura',
  mrn: 'MRN',
  mrnDate: 'Data MRN',
  customsClearance: 'Visto Uscire',
  awb: 'AWB',
  awbDate: 'Data AWB',
  boxNumber: 'Nr Box',
  purchaseOrder: 'P.O.',
  billImAReg6: 'Bolla IM A Reg. 6',
  billImAReg6Date: 'Data Bolla IM A Reg. 6',
  attachments: 'Nr allegati',
  items: 'Nr Item',
  assignUser: 'Utente Presa in Carico',
  assignDatetime: 'Data Presa in Carico',
  assignNote: 'Note Presa in Carico',
  linkedPaperworkIds: 'Archivi customs collegati',
} as unknown as Record<string, string>;

export default function ParcelExportButton(props: ParcelExportButtonProps): JSX.Element {
  const { sort, filters } = props;
  const { data: saleTypeData } = useReadSaleTypeListQuery();
  const { data: shipmentReasonData } = useReadShipmentReasonListQuery();
  const { data: statusData } = useReadStatusListQuery();
  const { data: plantData } = useReadPlantsQuery();
  const { data: countryData } = useReadCountriesQuery();
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const readParcelListParams = useMemo(
    () => ({
      limit: 10000,
      offset: 0,
      sort,
      filters,
    }),
    [filters, sort],
  );

  const { data: parcelList, isFetching } = useReadParcelListQuery(readParcelListParams, {
    skip: !downloadingCsv,
  });

  const handleExport = useCallback((): void => {
    if (!parcelList) {
      return;
    }

    // Flatting the items on the parcel object, generating a row for each item with the corresponding parcel
    const rows = [];

    for (const parcel of parcelList.data) {
      const { items, paperworks, ...parcelProperties } = parcel;

      for (const item of items) {
        const row = {
          ...parcelProperties,
          ...item,
          value: typeof item.value === 'number' ? numberUtils.format(item.value) : '',
          countervalue: typeof item.countervalue === 'number' ? numberUtils.format(item.countervalue) : '',
          weight: typeof item.weight === 'number' ? numberUtils.format(item.weight) : '',
          customsDutiesCountervalue:
            typeof item.customsDutiesCountervalue === 'number'
              ? numberUtils.format(item.customsDutiesCountervalue)
              : '',
          dutiesCountervalue:
            typeof item.dutiesCountervalue === 'number' ? numberUtils.format(item.dutiesCountervalue) : '',
          country: countryData?.byISO[parcel.country]?.name || parcel.country,
          plantId: plantData?.map[parcel.plantId]?.name || parcel.plantId,
          status: statusData?.map[parcel.status]?.name || parcel.status,
          saleType: saleTypeData?.map[parcel.saleType]?.name || parcel.saleType,
          shipmentReason: shipmentReasonData?.map[parcel.shipmentReason]?.name || parcel.shipmentReason,
          attachments: parcel.attachments.length,
          items: parcel.items.length,
          createDatetime: formatStringDate(parcel.createDatetime),
          customsReferenceNoDate: formatStringDate(parcel.customsReferenceNoDate),
          deadlineDate: formatStringDate(parcel.deadlineDate),
          linkedPaperworkIds: paperworks ? paperworks.map(({ id }) => `#${id}`).join(', ') : '',
          ...(parcel.militaryLicenseExpirationDate && {
            militaryLicenseExpirationDate: formatStringDate(parcel.militaryLicenseExpirationDate),
          }),
          ...(parcel.militaryLicenseIssueDate && {
            militaryLicenseIssueDate: formatStringDate(parcel.militaryLicenseIssueDate),
          }),
          ...(parcel.assignDatetime && {
            assignDate: formatStringDate(parcel.assignDatetime),
          }),
          ...(item.exAReg3Date && {
            exAReg3Date: formatStringDate(item.exAReg3Date),
          }),
          ...(item.mrnDate && {
            mrnDate: formatStringDate(item.mrnDate),
          }),
          ...(item.awbDate && {
            awbDate: formatStringDate(item.awbDate),
          }),
          ...(item.billImAReg6Date && {
            billImAReg6Date: formatStringDate(item.billImAReg6Date),
          }),
        };

        rows.push(row);
      }
    }

    const options = {
      rowDelimiter: ';',
      mapHeaders: (header: string) => {
        return headerMapObj[header] || header;
      },
      headers: ['id', 'createUser'],
    };

    jsonexport(rows, options, function (err: Error, csv: string) {
      // eslint-disable-next-line no-console
      if (err) return console.error(err);

      const blob = new Blob([csv], { type: 'text/csv' });
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute(
        'download',
        `customs-repository-archivio-regimi-doganali-${format(new Date(), 'yyyy-MM-dd-hh-mm-ss')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      setDownloadingCsv(false);
    });
  }, [countryData?.byISO, parcelList, plantData?.map, saleTypeData?.map, shipmentReasonData?.map, statusData?.map]);

  useEffect(() => {
    if (downloadingCsv && !isFetching) {
      handleExport();
    }
  }, [downloadingCsv, handleExport, isFetching]);

  return (
    <Button size="small" onClick={() => setDownloadingCsv(true)} startIcon={<TrayArrowDownIcon />}>
      Esporta
    </Button>
  );
}
