import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import { PaperworkTypeEnum } from '../../entities/PaperworkType';
import { PaperworkReport } from '../../entities/Report';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';
import { ReportBarChart, ReportBarChartColors } from './ReportBarChart';

interface PaperworkTypeChartProps {
  data: PaperworkReport[];
  title: string;
}

function PaperworkTypeChart(props: PaperworkTypeChartProps) {
  const { data, title } = props;
  const { data: typeData, isLoading } = useReadPaperworkTypeListQuery();

  const labels = data.map((d) => format(parseISO(d.date), 'LLLL', { locale: it }));

  const chartData = useMemo(() => {
    const typeMap: Record<string, number> = {};
    typeData?.list.forEach((type) => (typeMap[type.name] = type.id));
    return {
      labels,
      datasets: Object.values(PaperworkTypeEnum).map((type, i) => ({
        label: type,
        data: data.map((d) => d.paperworksByType.find((v) => v.type === typeMap[type])?.value),
        backgroundColor: ReportBarChartColors[i],
      })),
    };
  }, [data, labels, typeData?.list]);

  return <ReportBarChart title={title} chartData={chartData} loading={isLoading} />;
}

export default React.memo(PaperworkTypeChart);
