import { z } from 'zod';

export enum PaperworkTypeEnum {
  VariationTITE = 'Variazione T.I. / T.E.',
  Extension = 'Proroga',
  AdministrativeClearance = 'Appuramento amministrativo',
  ExpUeConversion = 'Trasformazione in definitiva exp vs UE',
  EASAConversion = 'Trasformazione definitiva EASA',
  PostClearanceRegularization = 'Regolarizzazione a posteriori',
  Correction = 'Rettifica',
  Scrap = 'Rottamazione',
  Nationalization = 'Nazionalizzazione',
  AssessmentReview = 'Revisione di accertamento',
  Others = 'Varie',
  DTTI = 'DTTI (T5)',
  ReliefFromTimeLimit = 'Rimessa in termini',
}

export const PaperworkTypeSchema = z.object({
  id: z.number(),
  name: z.nativeEnum(PaperworkTypeEnum),
});

export type PaperworkType = z.infer<typeof PaperworkTypeSchema>;
