import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomTableFooter, DataGrid, muiFiltersToPagedRequestFilters } from '@top-solution/microtecnica-mui';
import { useAuth, PagedRequestParams } from '@top-solution/microtecnica-utils';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowModel,
  GridRowParams,
  GridSlotsComponentsProps,
  GridSortModel,
  GridValueFormatterParams,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { Attachment } from '../entities/Attachment';
import { Paperwork } from '../entities/Paperwork';
import { ParcelPaperwork } from '../entities/Parcel';
import { UserRole } from '../entities/User';
import { usePaperworkStatus } from '../hooks/usePaperworkStatus';
import { usePaperworkStatusColDef } from '../hooks/usePaperworkStatusColDef';
import { usePaperworkTypeColDef } from '../hooks/usePaperworkTypeColDef';
import PaperworkExportButton from '../pages/Paperwork/PaperworkExportButton';
import { ParcelLinkButton } from '../pages/Paperwork/ParcelLinkButton';
import { formatStringDate } from '../utils';
import { AddIcon, ChartBarStackedIcon, CopyIcon, DeleteIcon, EditIcon } from './Icons';

const pinnedColumns: GridPinnedColumns = { right: ['actions'] };

interface PaperworkTableProps {
  paperworks: (Paperwork | ParcelPaperwork)[];
  total?: number;
  loading?: boolean;
  minimal: boolean;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: Partial<GridPaginationModel>) => void;
  onDelete?: (params: GridRowParams) => void;
  onParamsChange?: (params: PagedRequestParams) => void;
}

export default function PaperworkTable(props: PaperworkTableProps): JSX.Element {
  const { minimal, loading, total, paperworks, onDelete, onParamsChange, paginationModel, onPaginationModelChange } =
    props;
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const { isAdmin, hasRole, roles } = useAuth();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'createDatetime', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const { getPaperworkStatus } = usePaperworkStatus();

  // READ_PAPERWORKS is required to link parcels to paperworks
  const userCanEditPaperworks = isAdmin || (hasRole(UserRole.WRITE_PAPERWORKS) && hasRole(UserRole.READ_PARCELS));
  const userCanDeletePaperworks = isAdmin || hasRole(UserRole.WRITE_PAPERWORKS);

  const sort = useMemo(() => sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`), [sortModel]);

  const filters = useMemo(() => [...muiFiltersToPagedRequestFilters(filterModel.items)], [filterModel.items]);

  const readPaperworkListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize as number,
      offset: (paginationModel.pageSize as number) * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: [...muiFiltersToPagedRequestFilters(filterModel.items)],
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      onPaginationModelChange({ page: 0 });
    },
    [onPaginationModelChange],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      onPaginationModelChange({ page: 0 });
    },
    [onPaginationModelChange],
  );

  const handleDeleteClick = useCallback(
    (params: GridRowParams) => (event: MuiEvent<React.MouseEvent>) => {
      event.stopPropagation();
      onDelete && onDelete(params);
    },
    [onDelete],
  );

  const statusColumn = usePaperworkStatusColDef();
  const typeColumn = usePaperworkTypeColDef();

  const rows = useMemo<GridRowModel[]>(() => paperworks.map((item) => ({ ...item })) || [], [paperworks]);

  const columns = useMemo<GridColDef<Paperwork | ParcelPaperwork>[]>(() => {
    const parcelCols: GridColDef<Paperwork | ParcelPaperwork>[] = minimal
      ? []
      : [
          {
            field: 'parcel',
            headerName: 'Regime Doganale collegato',
            type: 'number',
            width: 100,
            filterable: false, // We are going to implement it on request
            groupable: false,
            renderCell: ({ value }) => (value ? <ParcelLinkButton parcel={value} /> : `—`),
            valueFormatter: ({ value }) => value?.id,
          },
          {
            field: 'license',
            headerName: 'Riferimento Licenza',
            width: 150,
            renderCell: ({ value }) => (value ? value : `—`),
          },
        ];

    const attachmentCols: GridColDef<Paperwork | ParcelPaperwork>[] = minimal
      ? []
      : [
          {
            field: 'attachments',
            headerName: 'Nr Allegati',
            width: 100,
            filterable: false,
            sortable: false,
            valueFormatter: ({ value }: GridValueFormatterParams<Attachment[]>) => value?.length || '—',
            align: 'right',
          },
        ];

    const actionCols: GridColDef<Paperwork | ParcelPaperwork>[] =
      !minimal && (userCanEditPaperworks || userCanDeletePaperworks)
        ? [
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Azioni',
              renderHeader: () => (
                <Tooltip title="Aggiungi Pratica">
                  <IconButton color="primary" onClick={() => navigate('./add')} sx={{ mx: 1 }}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ),
              getActions: (params: GridRowParams) => {
                const actions = [];

                if (userCanEditPaperworks) {
                  actions.push(
                    <GridActionsCellItem
                      icon={<EditIcon />}
                      key="edit"
                      label="Modifica"
                      onClick={() => navigate(`./${params.row.id}/edit`)}
                    />,
                  );
                }
                if (userCanEditPaperworks) {
                  actions.push(
                    <GridActionsCellItem
                      icon={<CopyIcon />}
                      key="duplicate"
                      label="Duplica"
                      onClick={() => navigate(`/paperwork/duplicate/${params.row.id}`)}
                    />,
                  );
                }
                if (userCanDeletePaperworks) {
                  actions.push(
                    <GridActionsCellItem
                      icon={<DeleteIcon />}
                      key="delete"
                      label="Elimina"
                      onClick={handleDeleteClick(params)}
                    />,
                  );
                }

                return actions;
              },
            },
          ]
        : [];

    const cols: GridColDef<Paperwork | ParcelPaperwork>[] = [
      {
        field: 'id',
        headerName: 'Nr pratica',
        type: 'number',
        renderCell: ({ value }) => (
          <Button size="small" color="primary" component={Link} to={`/paperwork/${value}`}>
            {value}
          </Button>
        ),
      },
      {
        field: 'createUser',
        headerName: 'Autore Pratica',
        width: 150,
      },
      {
        field: 'createDatetime',
        headerName: 'Data Inserimento Pratica',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
      },
      {
        field: 'applicant',
        headerName: 'Nome richiedente',
        width: 150,
      },
      {
        field: 'requestDate',
        headerName: 'Data Richiesta',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
      },
      {
        ...typeColumn,
        width: 150,
      },
      ...parcelCols,
      {
        ...statusColumn,
        width: 150,
      },
      {
        field: 'mtReferenceNo',
        headerName: 'Protocollo MT',
        width: 150,
        cellClassName: (params) => `status-${getPaperworkStatus(params.row)}`,
      },
      {
        field: 'mtReferenceNoDate',
        headerName: 'Data Protocollo MT',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }) => formatStringDate(value),
      },
      {
        field: 'customsProvisionNo',
        headerName: 'Protocollo Provvedimento Dogana',
        width: 150,
        valueFormatter: ({ value }) => (value === undefined ? `—` : `${value}`),
      },
      {
        field: 'customsProvisionNoDate',
        headerName: 'Data Provvedimento Dogana',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }) => (value === undefined ? `–` : formatStringDate(value)),
      },
      {
        field: 'customsDuties',
        headerName: 'Diritti Doganali',
        width: 150,
        type: 'number',
        valueFormatter: ({ value }) => (value === undefined ? `–` : `${value} €`),
        align: 'right',
      },
      {
        field: 'sanctions',
        headerName: 'Sanzioni',
        width: 150,
        type: 'number',
        valueFormatter: ({ value }) => (value === undefined ? `–` : `${value} €`),
        align: 'right',
      },
      ...attachmentCols,
      {
        field: 'note',
        headerName: 'Note',
        width: 150,
        filterable: false,
        sortable: false,
      },
      ...actionCols,
    ];
    return cols;
  }, [
    minimal,
    userCanEditPaperworks,
    userCanDeletePaperworks,
    typeColumn,
    statusColumn,
    navigate,
    handleDeleteClick,
    getPaperworkStatus,
  ]);

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange(readPaperworkListParams);
    }
  }, [onParamsChange, readPaperworkListParams]);

  return (
    <DataGrid
      density="compact"
      rows={rows}
      columns={columns}
      loading={loading}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      rowCount={total ?? rows.length}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      onFilterModelChange={handleFilterModelChange}
      filterModel={filterModel}
      editMode="row"
      apiRef={apiRef}
      sessionStorageId={props.minimal ? undefined : `PaperworkDataGridSessionId${roles?.toString()}`}
      pinnedColumns={pinnedColumns}
      autoHeight={minimal}
      disableAggregation
      disableRowGrouping
      sx={{
        '& .status-Annullata': {
          backgroundColor: green[300],
        },
        '& .status-Rilasciata': {
          backgroundColor: green[300],
        },
      }}
      slots={{
        footer: (props: NonNullable<GridSlotsComponentsProps['footer']>) =>
          minimal ? null : (
            <CustomTableFooter
              customButtons={[
                <PaperworkExportButton key="export" sort={sort} filters={filters} />,
                <Button
                  size="small"
                  key="report"
                  onClick={() => navigate(`/paperwork/report`)}
                  startIcon={<ChartBarStackedIcon />}
                >
                  Report
                </Button>,
              ]}
              disableExportSelector={true}
            >
              {props.children}
            </CustomTableFooter>
          ),
      }}
    />
  );
}
