import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const wait = () => new Promise((r) => setTimeout(r, 1000));

const mockFetchBaseQuery: typeof fetchBaseQuery =
  (...requestInit) =>
  async (queryArgs, ...otherargs) => {
    // eslint-disable-next-line
      let { url, method = 'GET', body, params } = queryArgs as FetchArgs;
    if (typeof queryArgs === 'string') {
      url = queryArgs;
    }
    if (url && method) {
      // nil
    }

    return fetchBaseQuery(...requestInit)(queryArgs, ...otherargs);
  };

export default mockFetchBaseQuery;
