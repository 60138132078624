import { ZodIssueCode, ZodIssueOptionalMessage } from 'zod';

declare interface ErrorMapCtx {
  defaultError: string;
}

export default function customErrorMap(issue: ZodIssueOptionalMessage, ctx: ErrorMapCtx): { message: string } {
  if (issue.code === ZodIssueCode.invalid_type) {
    if (issue.received === 'undefined' || issue.received === 'null') {
      return { message: 'Campo obbligatorio' };
    } else {
      return { message: `Tipo non valido (${ctx.defaultError})` };
    }
  }
  if (issue.code === ZodIssueCode.too_small) {
    if (issue.type === 'number') {
      return { message: `Valore minimo: ${issue.minimum}` };
    }
    if (issue.type === 'string') {
      return { message: `Lunghezza minima: ${issue.minimum} caratter${issue.minimum === 1 ? 'e' : 'i'}` };
    }
    if (issue.type === 'array') {
      return { message: `Lunghezza minima: ${issue.minimum} element${issue.minimum === 1 ? 'o' : 'i'}` };
    }
  }
  if (issue.code === ZodIssueCode.too_big) {
    if (issue.type === 'number') {
      return { message: `Valore massimo: ${issue.maximum}` };
    }
    if (issue.type === 'string') {
      return { message: `Lunghezza massima: ${issue.maximum} caratter${issue.maximum === 1 ? 'e' : 'i'}` };
    }
    if (issue.type === 'array') {
      return { message: `Lunghezza massima: ${issue.maximum} element${issue.maximum === 1 ? 'o' : 'i'}` };
    }
  }

  if (issue.code === ZodIssueCode.invalid_string) {
    if (issue.validation === 'regex') {
      return { message: `Formato non valido` };
    }
  }

  if (issue.code !== 'custom') {
    // eslint-disable-next-line no-console
    console.warn('Missing validation l10n:', issue);
  }

  return { message: ctx.defaultError };
}
