import React, { useEffect, useState } from 'react';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { endOfMonth, startOfMonth, sub } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { DatePicker } from '@mui/x-date-pickers-pro';
import { Layout } from '../../components/Layout';

import UnauthorizedPage from '../../pages/Error/UnauthorizedPage';
import { AppLayoutProps } from './Layout';

interface ReportLayoutProps {
  title: string;
  error: AppLayoutProps['error'];
  breadcrumbs: AppLayoutProps['breadcrumbs'];
  children: React.ReactNode;
  onIntervalChange: (interval: [Date, Date]) => void;
}

export default function ReportLayout(props: ReportLayoutProps): JSX.Element {
  const { title, error, breadcrumbs, onIntervalChange } = props;
  const [startMonth, setStartMonth] = useState<Date | null>(
    sub(startOfMonth(new Date()), {
      months: 1,
    }),
  );
  const [endMonth, setEndMonth] = useState<Date | null>(endOfMonth(new Date()));
  const [intervalError, setIntervalError] = useState({ errorStart: false, errorEnd: false });

  const handleSearch = () => {
    if (startMonth && endMonth) {
      const interval: [Date, Date] = [startOfMonth(startMonth), endOfMonth(endMonth)];
      onIntervalChange(interval);
    }
  };

  const isDisabled = () => {
    if (startMonth && endMonth) {
      if (intervalError.errorStart || intervalError.errorEnd) {
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    onIntervalChange([
      sub(startOfMonth(new Date()), {
        months: 1,
      }),
      endOfMonth(new Date()),
    ]);
  }, [onIntervalChange]);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        maxWidth={false}
        breadcrumbs={[...(breadcrumbs ?? []), { title }]}
        disableGutters
        error={error}
        sx={{ p: 1 }}
      >
        <Card sx={{ maxWidth: 1200, width: '100%', flex: '1 1 auto', margin: 'auto' }}>
          <CardContent>
            <Grid container spacing={6} rowSpacing={{ xs: 4, md: 8 }}>
              <Grid item md={6} xs={12}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <DatePicker
                    views={['year', 'month']}
                    label="Inizio"
                    minDate={new Date('2012-03-01')}
                    maxDate={endMonth}
                    value={startMonth}
                    onError={(reason) => {
                      setIntervalError({ ...intervalError, errorStart: !!reason });
                    }}
                    onChange={(newValue) => {
                      setStartMonth(newValue);
                    }}
                    slotProps={{
                      textField: {
                        helperText: null,
                        size: 'small',
                      },
                    }}
                  />
                  <Box sx={{ mx: 2 }}> a </Box>
                  <DatePicker
                    views={['year', 'month']}
                    label="Fine"
                    minDate={startMonth}
                    maxDate={endOfMonth(new Date())}
                    value={endMonth}
                    onError={(reason) => {
                      setIntervalError({ ...intervalError, errorEnd: !!reason });
                    }}
                    onChange={(newValue) => {
                      setEndMonth(newValue);
                    }}
                    slotProps={{
                      textField: {
                        helperText: null,
                        size: 'small',
                      },
                    }}
                  />
                  <Button variant="contained" onClick={handleSearch} sx={{ marginLeft: 2 }} disabled={isDisabled()}>
                    Cerca
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box mb={4} sx={{ display: 'flex', justifyContent: 'space-between' }}></Box>

            <Grid container spacing={6} rowSpacing={{ xs: 4, md: 8 }}>
              {props.children}
            </Grid>
          </CardContent>
        </Card>
      </Layout>
    </AuthGuard>
  );
}

interface ReportRowProps {
  title: string;
  table: React.ReactNode;
  chart?: React.ReactNode;
  height?: number;
  loading?: boolean;
}

export function ReportRow(props: ReportRowProps) {
  const { title, table, chart, height, loading } = props;

  return (
    <>
      <Grid item xs={12} md={chart ? 6 : 12}>
        {loading ? (
          <>
            <Skeleton sx={{ minWidth: 150, width: 300, height: 28 }} />
            <Skeleton sx={{ minWidth: 150, width: '100%', height: (height ?? 300) - 28 }} />
          </>
        ) : (
          <>
            <Typography variant="h5" mb={2}>
              {title}
            </Typography>
            <Box sx={{ width: '100%', height: height ?? 300 }}>{table}</Box>
          </>
        )}
      </Grid>
      {chart ? (
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          {loading ? <Skeleton sx={{ minWidth: 150, width: '100%', height: height ?? 300 }} /> : chart}
        </Grid>
      ) : null}
    </>
  );
}
