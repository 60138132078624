import { NumberUtils } from '@top-solution/microtecnica-utils';
import { format, formatISO, isValid, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';

export function formatStringDate(value: string): string {
  if (!value) return '';
  const date = new Date(value as string);
  if (isValid(date)) {
    return format(date, 'P', { locale: it });
  }
  return value;
}

export function formatISODate(date: Date): string {
  return formatISO(date, { representation: 'date' });
}

export function parseISODate(value: unknown): Date | null {
  if (typeof value === 'string') {
    const date = parseISO(value);
    if (isValid(date)) {
      return date;
    }
  }
  return null;
}

export const numberUtils = new NumberUtils({
  minimumFractionDigits: 2,
  maximumFractionDigits: 9,
});
