import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { NumericInput } from '@top-solution/microtecnica-mui';
import Box, { BoxProps } from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { FormRow } from '../../components/Form';
import AttachmentsField from '../../components/Form/AttachmentsField';
import { GenericSelect } from '../../components/Form/GenericSelect';
import { ParcelAutocomplete } from '../../components/Form/ParcelAutocomplete';
import { PaperworkAddForm } from '../../entities/Paperwork';
import { PaperworkStatusEnum } from '../../entities/PaperworkStatus';
import { PaperworkTypeEnum } from '../../entities/PaperworkType';
import { useReadPaperworkStatusListQuery } from '../../services/paperworkStatusApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';

type PaperworkEditFormProps = BoxProps & {
  form: UseFormReturn<PaperworkAddForm>;
};

export default function PaperworkEditForm(props: PaperworkEditFormProps): JSX.Element {
  const { form, ...boxProps } = props;

  const { control, watch, setValue } = form;
  const paperworkType = watch('type');
  const paperworkStatus = watch('status');

  const typeList = useReadPaperworkTypeListQuery();
  const statusList = useReadPaperworkStatusListQuery();

  return (
    <Box {...boxProps}>
      <FormRow>
        <Controller
          control={control}
          name="applicant"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ flex: 1 }}
              label="Nome richiedente"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
        <Controller
          control={control}
          name="requestDate"
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              slotProps={{
                textField: {
                  helperText: error?.message ?? ' ',
                  error: Boolean(error),
                  sx: { width: 350 },
                },
              }}
              label="Data richiesta"
              {...field}
              value={field.value ?? null}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="status"
          render={({ field, fieldState: { error } }) => (
            <GenericSelect
              {...field}
              label="Stato pratica"
              list={statusList.data?.list ?? []}
              disabled={statusList.isFetching}
              error={Boolean(error ?? statusList.error)}
              helperText={error?.message ?? ' '}
              sx={{ flex: 1 }}
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState: { error } }) => (
            <GenericSelect
              {...field}
              label="Tipologia pratica"
              list={typeList.data?.list ?? []}
              disabled={typeList.isFetching}
              error={Boolean(error ?? typeList.error)}
              helperText={error?.message ?? ' '}
              sx={{ flex: 1 }}
              onChange={(value) => {
                if (value && PaperworkTypeEnum.Extension !== typeList.data?.map[value].name) {
                  setValue('extensionDate', undefined);
                }
                field.onChange(value);
              }}
              value={field.value ?? null}
            />
          )}
        />
        {paperworkType && PaperworkTypeEnum.DTTI === typeList.data?.map[paperworkType].name && (
          <Controller
            control={control}
            name="license"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Riferimento licenza"
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                sx={{ width: '350px' }}
                {...field}
                value={field.value ?? null}
              />
            )}
          />
        )}
        {paperworkType && PaperworkTypeEnum.DTTI !== typeList.data?.map[paperworkType].name && (
          <Controller
            control={control}
            name="parcelId"
            render={({ field, fieldState: { error } }) => (
              <ParcelAutocomplete
                label="Pratica collegata"
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                sx={{ flex: 1, minWidth: '350px' }}
                {...field}
                onChange={(_, value) => setValue('parcelId', typeof value === 'string' ? value : undefined)}
                value={field.value ?? null}
              />
            )}
          />
        )}
        {paperworkType &&
          PaperworkTypeEnum.Extension === typeList.data?.map[paperworkType].name &&
          paperworkStatus &&
          PaperworkStatusEnum.Released === statusList.data?.map[paperworkStatus].name && (
            <Controller
              control={control}
              name="extensionDate"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  slotProps={{
                    textField: {
                      helperText: error?.message ?? ' ',
                      error: Boolean(error),
                      sx: { width: 350 },
                    },
                  }}
                  label="Data proroga"
                  {...field}
                  value={field.value ?? null}
                />
              )}
            />
          )}
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="mtReferenceNo"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ flex: 1 }}
              label="Protocollo MT"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="mtReferenceNoDate"
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              slotProps={{
                textField: {
                  helperText: error?.message ?? ' ',
                  error: Boolean(error),
                  sx: { width: 350 },
                },
              }}
              label="Data protocollo MT"
              {...field}
              value={field.value ?? null}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="customsProvisionNo"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ flex: 1 }}
              label="Protocollo provvedimento Dogana"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
        <Controller
          control={control}
          name="customsProvisionNoDate"
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              slotProps={{
                textField: {
                  helperText: error?.message ?? ' ',
                  error: Boolean(error),
                  sx: { width: 350 },
                },
              }}
              label="Data provvedimento dogana"
              {...field}
              value={field.value ?? null}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="customsDuties"
          render={({ field, fieldState: { error } }) => (
            <NumericInput
              options={{ minimumFractionDigits: 2 }}
              sx={{ flex: 1 }}
              label="Diritti Doganali (€)"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
              value={field.value ?? null}
            />
          )}
        />
        <Controller
          control={control}
          name="sanctions"
          render={({ field, fieldState: { error } }) => (
            <NumericInput
              options={{ minimumFractionDigits: 2 }}
              sx={{ flex: 1 }}
              label="Sanzioni (€)"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              {...field}
              value={field.value ?? null}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="note"
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: '100%' }}
              label="Note"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
              multiline
              rows={4}
              {...field}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="attachments"
          render={({ field, fieldState: { error } }) => (
            <AttachmentsField
              {...field}
              value={field.value ?? []}
              sx={{ flex: 1 }}
              label="Allegati"
              error={Boolean(error)}
              helperText={error?.message ?? ' '}
            />
          )}
        />
      </FormRow>
    </Box>
  );
}
