import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MUIPremiumKey } from '@top-solution/microtecnica-mui';
import { CommonDataApiProvider, setAuthApiUrl } from '@top-solution/microtecnica-utils';
import it from 'date-fns/locale/it';
import { createRoot } from 'react-dom/client';
import { z } from 'zod';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400-italic.css';
import './index.css';
import { App } from './App';
import customErrorMap from './customErrorMap';
import { store } from './store';
import { theme } from './theme';

const appId = `${import.meta.env.VITE_APP_ID}`;
const appVersion = `${import.meta.env.VITE_VERSION}`;
const authApi = `${import.meta.env.VITE_AUTH_API}`;

// eslint-disable-next-line
console.log(`🚀 ${appId} (v. ${appVersion}) 🚀`);

setAuthApiUrl(`${authApi}/api`);

LicenseInfo.setLicenseKey(MUIPremiumKey);

z.setErrorMap(customErrorMap);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <CommonDataApiProvider value={{ appId, authApi }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <BrowserRouter>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </CommonDataApiProvider>
  </React.StrictMode>,
);
