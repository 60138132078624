import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DownloadIcon } from '../Icons';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ReportBarChartComponentProps {
  chartData: ChartData<'bar', unknown, unknown>;
  loading?: boolean;
  title: string;
}

export const ReportBarChartColors = [
  'rgb(255, 99, 132)',
  'rgb(53, 162, 235)',
  'rgb(75, 192, 192)',
  'rgb(75, 192, 112)',
  'rgb(255, 159, 64)',
  'rgb(249, 125, 237)',
  'rgb(170, 249, 65)',
  'rgb(255, 205, 86)',
  'rgb(201, 203, 207)',
  'rgb(153, 102, 255)',
  'rgb(53, 162, 205)',
  'rgb(255, 99, 112)',
];

function ReportBarChartComponent(props: ReportBarChartComponentProps) {
  const { chartData, loading, title } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartRef = useRef<any>(null);

  const options = useMemo(
    () => ({
      plugins: {
        title: {
          display: true,
          text: title,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    }),
    [title],
  );

  const handleDownload = () => {
    const chart = chartRef.current;

    if (chart) {
      const image = chart.canvas.toDataURL();
      // Create a link
      const aDownloadLink = document.createElement('a');
      aDownloadLink.download = `${title.toLocaleLowerCase().replaceAll(' ', '-')}.png`;
      aDownloadLink.href = image;
      aDownloadLink.click();
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ flex: '1 1 auto', position: 'relative' }}>
      <Button
        sx={{ ml: 'auto', position: 'absolute', top: 3, right: 0 }}
        size="small"
        variant="text"
        onClick={() => handleDownload()}
        startIcon={<DownloadIcon />}
      >
        Scarica
      </Button>
      <Bar ref={chartRef} options={options} data={chartData} />
    </Box>
  );
}

export const ReportBarChart = React.memo(ReportBarChartComponent);
