import {
  ParcelReportSchema,
  ParcelReport,
  PaperworkReport,
  PaperworkReportSchema,
  ParcelHtsReportSchema,
  ParcelHtsReport,
} from '../entities/Report';
import { TAG_TYPES, api } from './baseApi';

const url = 'v1/report';

const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readParcelReport: builder.query<ParcelReport[], { start: string; end: string }>({
      query: (params) => ({ url: `${url}/parcel?start=${params.start}&end=${params.end}` }),
      transformResponse: (data) => ParcelReportSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.REPORT, id: 'REPORT-PARCEL' }],
    }),
    readParcelHtsReport: builder.query<ParcelHtsReport[], { start: string; end: string }>({
      query: (params) => ({ url: `${url}/parcel/hts?start=${params.start}&end=${params.end}` }),
      transformResponse: (data) => ParcelHtsReportSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.REPORT, id: 'REPORT-PARCEL-HTS' }],
    }),
    readPaperworkReport: builder.query<PaperworkReport[], { start: string; end: string }>({
      query: (params) => ({ url: `${url}/paperwork?start=${params.start}&end=${params.end}` }),
      transformResponse: (data) => PaperworkReportSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.REPORT, id: 'REPORT-PAPERWORK' }],
    }),
  }),
  overrideExisting: false,
});

export const { useReadParcelReportQuery, useReadParcelHtsReportQuery, useReadPaperworkReportQuery } = reportApi;
