import React from 'react';
import { UnauthorizedBox } from '@top-solution/microtecnica-mui';
import { Layout } from '../../components/Layout';

interface UnauthorizedPageProps {
  description?: string;
}

export default function UnauthorizedPage({ description }: UnauthorizedPageProps): JSX.Element {
  return (
    <Layout title="Accesso non autorizzato" maxWidth="sm">
      <UnauthorizedBox description={description} />
    </Layout>
  );
}
