import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import UserSection from '@mdi/svg/svg/account-cog.svg?react';
import AccountHardHat from '@mdi/svg/svg/account-hard-hat.svg?react';
import Alert from '@mdi/svg/svg/alert.svg?react';
import ChartBarStacked from '@mdi/svg/svg/chart-bar-stacked.svg?react';
import ChevronDown from '@mdi/svg/svg/chevron-down.svg?react';
import Close from '@mdi/svg/svg/close.svg?react';
import Copy from '@mdi/svg/svg/content-copy.svg?react';
import ContentSave from '@mdi/svg/svg/content-save.svg?react';
import Download from '@mdi/svg/svg/download.svg?react';
import Upload from '@mdi/svg/svg/file-upload.svg?react';
import File from '@mdi/svg/svg/file.svg?react';
import Paperwork from '@mdi/svg/svg/inbox-full.svg?react';
import Parcel from '@mdi/svg/svg/package-variant.svg?react';
import Pencil from '@mdi/svg/svg/pencil.svg?react';
import Add from '@mdi/svg/svg/plus-circle.svg?react';
import TrashCan from '@mdi/svg/svg/trash-can.svg?react';
import TrayArrowDown from '@mdi/svg/svg/tray-arrow-down.svg?react';

const createIcon = (icon: React.ReactNode) =>
  function Icon(props: SvgIconProps): JSX.Element {
    return <SvgIcon {...props}>{icon}</SvgIcon>;
  };

export const AccountHardHatIcon = createIcon(<AccountHardHat />);
export const AddIcon = createIcon(<Add />);
export const AlertIcon = createIcon(<Alert />);
export const CancelIcon = createIcon(<Close />);
export const ChartBarStackedIcon = createIcon(<ChartBarStacked />);
export const ChevronDownIcon = createIcon(<ChevronDown />);
export const CopyIcon = createIcon(<Copy />);
export const DeleteIcon = createIcon(<TrashCan />);
export const DownloadIcon = createIcon(<Download />);
export const EditIcon = createIcon(<Pencil />);
export const FileIcon = createIcon(<File />);
export const PaperworkIcon = createIcon(<Paperwork />);
export const ParcelIcon = createIcon(<Parcel />);
export const SaveIcon = createIcon(<ContentSave />);
export const TrayArrowDownIcon = createIcon(<TrayArrowDown />);
export const UploadIcon = createIcon(<Upload />);
export const UserSectionIcon = createIcon(<UserSection />);
