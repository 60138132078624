import { useCallback, useMemo } from 'react';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';

import { useReadPaperworkStatusListQuery } from '../services/paperworkStatusApi';

export function usePaperworkStatusColDef(): GridColDef {
  const { data } = useReadPaperworkStatusListQuery();

  const valueOptions = useMemo(() => data?.list.map((v) => ({ value: v.id, label: v.name })), [data]);
  const valueFormatter = useCallback(
    ({ value }: GridValueFormatterParams) =>
      typeof value === 'number' ? data?.map[value]?.name || `⚠️ ${value}` : value,
    [data],
  );
  return {
    field: 'status',
    headerName: 'Stato Richiesta',
    type: 'singleSelect',
    valueOptions,
    valueFormatter,
  };
}
