import {
  PagedRequestParams,
  PagedResponse,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import { Parcel, ParcelList, ParcelListSchema, ParcelSchema } from '../entities/Parcel';
import { FilterFields } from '../pages/Parcel/ParcelAccordion';
import { TAG_TYPES, api } from './baseApi';

const url = 'v1/parcels';

const ParcelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readParcelList: builder.query<PagedResponse<ParcelList>, PagedRequestParams>({
      query: (params) => {
        const itemsFields = Object.values(FilterFields) as string[];
        const itemsFilters = params.filters?.reduce((acc, curr) => {
          if (itemsFields.includes(curr.field)) {
            return { ...acc, [curr.field]: curr.value };
          }
          return acc;
        }, {});
        const newParams = {
          ...params,
          filters: params.filters?.filter((f) => !itemsFields.includes(f.field)),
          ...itemsFilters,
        };
        return { url, params: formatQueryParams(newParams) };
      },
      transformResponse: (data) => createPagedResponseSchema(ParcelListSchema).parse(data),
      providesTags: (result) =>
        result ? [TAG_TYPES.PARCEL, ...result.data.map(({ id }) => ({ type: TAG_TYPES.PARCEL, id }))] : [],
    }),
    readParcel: builder.query<Parcel, string>({
      query: (id) => ({ url: `${url}/${id}` }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (data: any) => ParcelSchema.parse(data),
      providesTags: (result) => [{ type: TAG_TYPES.PARCEL, id: result?.id }],
    }),
    createParcel: builder.mutation<
      Parcel,
      Omit<Parcel, 'attachments' | 'id' | 'createUser' | 'createDatetime'> & {
        attachments: number[];
      }
    >({
      query: (parcel) => ({ url, method: 'POST', body: parcel }),
      invalidatesTags: () => [TAG_TYPES.PARCEL],
    }),
    updateParcel: builder.mutation<
      Parcel,
      Omit<Parcel, 'attachments' | 'createUser' | 'createDatetime'> & {
        attachments: number[];
      }
    >({
      query: (Parcel) => ({ url: `${url}/${Parcel.id}`, method: 'PUT', body: Parcel }),
      invalidatesTags: (result) =>
        result
          ? [
              TAG_TYPES.PARCEL,
              { type: TAG_TYPES.PARCEL, id: result.id },
              ...(result.paperworks ?? []).map(({ id }) => ({ type: TAG_TYPES.PAPERWORK, id: id })),
            ]
          : [],
    }),
    assignParcel: builder.mutation<void, { id: string; note: string }>({
      query: ({ id, note }) => ({ url: `${url}/${id}/assignee`, method: 'POST', body: { assignNote: note } }),
      invalidatesTags: (result, error, arg) => (arg ? [TAG_TYPES.PARCEL, { type: TAG_TYPES.PARCEL, id: arg.id }] : []),
    }),
    closeParcel: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({ url: `${url}/${id}/close`, method: 'POST' }),
      invalidatesTags: (result, error, arg) => (arg ? [TAG_TYPES.PARCEL, { type: TAG_TYPES.PARCEL, id: arg.id }] : []),
    }),
    removeParcel: builder.mutation<void, Parcel>({
      query: (Parcel) => ({ url: `${url}/${Parcel.id}`, method: 'DELETE' }),
      invalidatesTags: (result, error, arg) => (arg ? [TAG_TYPES.PARCEL, { type: TAG_TYPES.PARCEL, id: arg.id }] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadParcelListQuery,
  useReadParcelQuery,
  useCreateParcelMutation,
  useRemoveParcelMutation,
  useAssignParcelMutation,
  useCloseParcelMutation,
  useUpdateParcelMutation,
  useLazyReadParcelListQuery,
} = ParcelApi;
