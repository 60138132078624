import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Parcel } from '../entities/Parcel';
import { StatusEnum } from '../entities/Status';
import { useReadStatusListQuery } from '../services/statusApi';
import { formatStringDate, parseISODate } from '../utils';
import { AlertIcon, AccountHardHatIcon } from './Icons';

interface ExpirationFormattedProps {
  parcel: Parcel;
  typographyOptions?: TypographyProps;
}

export default function ExpirationFormatted(props: ExpirationFormattedProps): JSX.Element {
  const { parcel, typographyOptions } = props;

  const { data: statusData } = useReadStatusListQuery();

  if (statusData?.map[parcel.status]?.name !== StatusEnum.Close) {
    const deadlineDate = parseISODate(parcel.deadlineDate);
    const isExpired = deadlineDate !== null && new Date() > deadlineDate;
    if (isExpired) {
      if (parcel.assignUser) {
        return (
          <Tooltip title={`Presa in carico da ${parcel.assignUser}`} arrow>
            <Typography
              variant="body2"
              {...typographyOptions}
              sx={{ color: 'warning.main', display: 'flex', alignItems: 'center' }}
            >
              {formatStringDate(parcel.deadlineDate)}
              <AccountHardHatIcon sx={{ width: 16, height: 16, marginLeft: 1 }} />
            </Typography>
          </Tooltip>
        );
      }
      return (
        <Typography
          variant="body2"
          {...typographyOptions}
          sx={{ color: 'error.main', display: 'flex', alignItems: 'center' }}
        >
          {formatStringDate(parcel.deadlineDate)}
          <AlertIcon sx={{ width: 16, height: 16, marginLeft: 1 }} />
        </Typography>
      );
    }
  }

  return (
    <Typography variant="body2" {...typographyOptions}>
      {formatStringDate(parcel.deadlineDate)}
    </Typography>
  );
}
