import { PagedResponse } from '@top-solution/microtecnica-utils';
import { PaperworkType, PaperworkTypeSchema } from '../entities/PaperworkType';
import { api } from './baseApi';

const url = 'v1/paperwork-types';

const PaperworkTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPaperworkTypeList: builder.query<{ list: PaperworkType[]; map: Record<string, PaperworkType> }, void>({
      query: () => url,
      transformResponse: (data: PagedResponse<PaperworkType>) => {
        const list = PaperworkTypeSchema.array().parse(data.data);
        const map: Record<string, PaperworkType> = {};
        list.forEach((type) => (map[type.id] = type));
        return { list, map };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadPaperworkTypeListQuery } = PaperworkTypeApi;
