import { useMemo } from 'react';
import { z } from 'zod';
import { ParcelAddFormSchema } from '../entities/Parcel';
import { SaleTypeEnum } from '../entities/SaleType';
import { useReadSaleTypeListQuery } from '../services/saleTypeApi';

export function useParcelSchemaWIthMilitaryCheck() {
  const { data: saleTypeData, isLoading } = useReadSaleTypeListQuery();

  const ParcelAddFormSchemaWithMilitaryCheck = useMemo(() => {
    if (isLoading) {
      return null;
    }
    return ParcelAddFormSchema.superRefine((val, ctx) => {
      if (
        SaleTypeEnum.Military === saleTypeData?.map[val.saleType].name ||
        SaleTypeEnum.DualUse === saleTypeData?.map[val.saleType].name
      ) {
        if (!val.militaryLicense) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio',
            path: ['militaryLicense'],
          });
        }
        if (!val.militaryLicenseIssueDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio',
            path: ['militaryLicenseIssueDate'],
          });
        }
        if (!val.militaryLicenseExpirationDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio',
            path: ['militaryLicenseExpirationDate'],
          });
        }
      }
    });
  }, [saleTypeData?.map, isLoading]);

  return { ParcelAddFormSchemaWithMilitaryCheck, isLoading };
}
