import React from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadCurrencyListQuery } from '../../services/currencyApi';

type CurrencySelectProps = Omit<TextFieldProps, 'select'>;

function CurrencySelectComponent(props: CurrencySelectProps, ref: React.Ref<unknown>): JSX.Element {
  const { value, disabled, helperText, SelectProps, ...textFieldProps } = props;
  const { data, isFetching, error } = useReadCurrencyListQuery();
  const apiError = error as ServerError;
  const zodError = error as ZodError;

  return (
    <TextField
      select
      value={isFetching ? '' : value ?? ''}
      disabled={disabled || isFetching}
      error={Boolean(error)}
      helperText={apiError?.data?.message ?? zodError?.message ?? helperText}
      SelectProps={{ ref, ...SelectProps }}
      {...textFieldProps}
    >
      {data?.list.map(({ code }) => (
        <MenuItem key={code} value={code}>
          {code}
        </MenuItem>
      )) ?? <MenuItem></MenuItem>}
    </TextField>
  );
}

export const CurrencySelect = React.forwardRef(CurrencySelectComponent);
