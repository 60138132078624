import { PagedResponse } from '@top-solution/microtecnica-utils';
import { PaperworkStatus, PaperworkStatusSchema } from '../entities/PaperworkStatus';
import { api } from './baseApi';

const url = 'v1/paperwork-statuses';

const paperworkStatusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPaperworkStatusList: builder.query<{ list: PaperworkStatus[]; map: Record<string, PaperworkStatus> }, void>({
      query: () => url,
      transformResponse: (data: PagedResponse<PaperworkStatus>) => {
        const list = PaperworkStatusSchema.array().parse(data.data);
        const map: Record<string, PaperworkStatus> = {};
        list.forEach((status) => (map[status.id] = status));
        return { list, map };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadPaperworkStatusListQuery } = paperworkStatusApi;
