import { createApi } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import mockFetchBaseQuery from './mockFetchBaseQuery';

export const TAG_TYPES = {
  PARCEL: 'Parcel',
  PAPERWORK: 'Paperwork',
  PART_NUMBER: 'PartNumber',
  REPORT: 'Report',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: mockFetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});
