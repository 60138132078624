import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { PaperworkAddForm } from '../../entities/Paperwork';
import { PaperworkStatusEnum } from '../../entities/PaperworkStatus';
import { PaperworkTypeEnum } from '../../entities/PaperworkType';
import { UserRole } from '../../entities/User';
import { usePaperworkSchemaWithTypeCheck } from '../../hooks/usePaperworkSchemaWithTypeCheck';
import { usePaperworkStatus } from '../../hooks/usePaperworkStatus';
import { useReadPaperworkQuery, useUpdatePaperworkMutation } from '../../services/paperworkApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';
import { formatISODate, parseISODate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { PaperworkSection } from '../sections';
import { PaperworkEditCard } from './PaperworkEditCard';

const title = 'Modifica pratica';
const breadcrumbs = [{ title: PaperworkSection.title, url: PaperworkSection.url }];

export default function PaperworkEditPage(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams<'id'>();
  const paperworkId = params.id ? decodeURIComponent(params.id) : '';
  const paperwork = useReadPaperworkQuery(paperworkId);
  const [update, updateStatus] = useUpdatePaperworkMutation();
  const { PaperworkAddSchemaWithTypeCheck, statusIsLoading, typeIsLoading } = usePaperworkSchemaWithTypeCheck();
  const paperworkType = useReadPaperworkTypeListQuery();
  const typeList = useReadPaperworkTypeListQuery();
  const { getPaperworkStatus } = usePaperworkStatus();

  const defaultValues = useMemo(() => {
    if (!paperwork.data) {
      return undefined;
    }

    const requestDate = parseISODate(paperwork.data.requestDate);
    const mtReferenceNoDate = parseISODate(paperwork.data.mtReferenceNoDate);

    if (!requestDate || !mtReferenceNoDate) {
      return null;
    }

    const defaultValues: PaperworkAddForm = {
      ...paperwork.data,
      requestDate,
      mtReferenceNoDate,
      customsProvisionNoDate: paperwork.data.customsProvisionNoDate
        ? parseISODate(paperwork.data.customsProvisionNoDate) ?? undefined
        : undefined,
      parcelId: paperwork.data.parcel ? `${paperwork.data.parcel.id}` : undefined,
      extensionDate:
        PaperworkTypeEnum.Extension === typeList.data?.map[paperwork.data.type].name &&
        paperwork.data.parcel?.deadlineDate
          ? new Date(paperwork.data.parcel?.deadlineDate)
          : undefined,
    };

    return defaultValues;
  }, [paperwork.data, typeList.data?.map]);

  const handleSubmit = useCallback(
    async (data: PaperworkAddForm) => {
      const isDDTI = PaperworkTypeEnum.DTTI === paperworkType.data?.map[data.type].name;

      let extensionDate = data.extensionDate ? formatISODate(data.extensionDate) : undefined;
      if (getPaperworkStatus(data) !== PaperworkStatusEnum.Released) {
        extensionDate = undefined;
      }

      await update({
        ...data,
        id: parseInt(paperworkId),
        attachments: (data.attachments ?? []).map((a) => a.id),
        requestDate: formatISODate(data.requestDate),
        mtReferenceNoDate: formatISODate(data.mtReferenceNoDate),
        customsProvisionNo: data.customsProvisionNo ?? undefined,
        customsProvisionNoDate: data.customsProvisionNoDate ? formatISODate(data.customsProvisionNoDate) : undefined,
        license: isDDTI ? data.license : '',
        parcelId: !isDDTI && data.parcelId ? parseInt(data.parcelId) : undefined,
        extensionDate,
      }).unwrap();
      navigate('..');
    },
    [getPaperworkStatus, navigate, paperworkId, paperworkType.data?.map, update],
  );

  return (
    <AuthGuard
      authorizeRole={(role) => role === UserRole.WRITE_PARCELS || role === UserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Layout
        breadcrumbs={[...breadcrumbs, { title: paperworkId, url: `${PaperworkSection.url}/${paperworkId}` }, { title }]}
        disableGutters
        sx={{ p: 1 }}
        error={paperwork.error}
        inProgress={paperwork.isFetching || statusIsLoading || typeIsLoading}
        title={`${title} ${paperworkId}`}
      >
        {defaultValues && (
          <PaperworkEditCard
            title={`${title} ${paperworkId}`}
            resolver={PaperworkAddSchemaWithTypeCheck}
            defaultValues={defaultValues}
            isLoading={updateStatus.isLoading}
            error={updateStatus.error}
            onSubmit={handleSubmit}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
