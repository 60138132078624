import { z } from 'zod';

export const CurrencySchema = z.object({
  code: z.string().nonempty(),
  name: z.string().nonempty(),
  nameIt: z.string().nonempty(),
  symbol: z.string().nonempty(),
});

export type Currency = z.infer<typeof CurrencySchema>;
