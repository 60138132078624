import React, { useMemo, useState } from 'react';

import { DataGrid } from '@top-solution/microtecnica-mui';
import {
  DataGridPremium,
  GridColDef,
  GridRowModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import ParcelCounterTotalChart from '../../components/Charts/ParcelCounterTotalChart';
import ParcelStatusChart from '../../components/Charts/ParcelStatusChart';
import ParcelTotalChart from '../../components/Charts/ParcelTotalChart';

import ReportLayout, { ReportRow } from '../../components/Layout/ReportLayout';
import { ParcelReport, ReportCountervaluesEnum, ValueByCurrency } from '../../entities/Report';
import { useStatusColDef } from '../../hooks/useStatusColDef';

import { useReadParcelHtsReportQuery, useReadParcelReportQuery } from '../../services/reportApi';

import { formatISODate } from '../../utils';
import { ParcelSection } from '../sections';

const breadcrumbs = [{ title: ParcelSection.title, url: ParcelSection.url }];

interface ParcelStatusesValuesType {
  status: number;
  ti: number;
  te: number;
}

export interface ParcelStatusesByMonthType {
  date: string;
  values: ParcelStatusesValuesType[];
}

export interface ParcelTotalsByMonthType {
  date: string;
  totals: Record<string, number | string | undefined>[];
}

interface ParcelCountervalueTotalsType {
  total: string;
  ti: number;
  te: number;
}

export interface ParcelCountervalueTotalsByMonthType {
  date: string;
  totals: ParcelCountervalueTotalsType[];
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

interface ParcelReportTotalRowProps {
  data: ParcelReport[] | undefined;
  type: keyof Omit<ParcelReport, 'date'>;
  loading: boolean;
  title: string;
  chartTitle: string;
}

function flatTotal(total: ValueByCurrency[]) {
  return total.reduce((acc2, curr2) => {
    const obj = {} as Record<string, number>;
    obj[curr2.currency as string] = curr2.value;
    return { ...acc2, ...obj };
  }, {});
}

function ParcelReportTotalRow(props: ParcelReportTotalRowProps): JSX.Element {
  const { data, type, loading, title, chartTitle } = props;

  const parcelTotalByMonth = useMemo(
    () =>
      data?.reduce((acc, curr) => {
        const totalValues = flatTotal(curr[type].totalValues);
        const totalByMonthObj = {
          date: curr.date,
          totals: [{ total: 'totalValues', ...totalValues }],
        };
        acc.push(totalByMonthObj);
        return acc;
      }, [] as ParcelTotalsByMonthType[]) ?? [],
    [data, type],
  );

  const rows = useMemo<GridRowModel[]>(() => {
    if (!data) {
      return [];
    }

    const byCurrency: Record<string, number> = {};
    const rows: { id: string; value: number }[] = [];

    for (const datum of data) {
      const valuesByCurrency = datum[type].totalValues;

      for (const value of valuesByCurrency) {
        byCurrency[value.currency] = (byCurrency[value.currency] ?? 0) + value.value;
      }
    }

    Object.keys(byCurrency).forEach((currency) => {
      rows.push({
        id: currency,
        value: byCurrency[currency],
      });
    });

    return rows.sort((a, b) => {
      if (a.id === 'EUR') {
        return -1;
      }
      if (b.id === 'EUR') {
        return 1;
      }
      return a.id.localeCompare(b.id);
    });
  }, [data, type]);

  const currencies = useMemo<string[]>(() => {
    if (!rows) {
      return [];
    }

    return rows.map((r) => r.id);
  }, [rows]);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'id',
        headerName: '',
        flex: 1,
        minWidth: 120,
        filterable: true,
        sortable: true,
      },
      {
        field: 'value',
        headerName: 'Valore',
        flex: 1,
        minWidth: 100,
        filterable: true,
        sortable: true,
        valueFormatter: function ({ value }: GridValueFormatterParams) {
          return value.toLocaleString();
        },
      },
    ];
  }, []);

  return (
    <ReportRow
      loading={loading}
      title={title}
      height={380}
      table={
        <DataGridPremium
          density="compact"
          // autoHeight
          rows={rows || []}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            footer: CustomToolbar,
          }}
        />
      }
      chart={<ParcelTotalChart data={parcelTotalByMonth} title={chartTitle} currencies={currencies} />}
    />
  );
}

function ParcelReportCounterTotalRow(
  props: Pick<ParcelReportTotalRowProps, 'data'> & { title: string; chartTitle: string; loading: boolean },
): JSX.Element {
  const { data, title, chartTitle, loading } = props;

  const parcelTotalByMonth = useMemo(
    () =>
      data?.reduce((acc, curr) => {
        const countervalue = {
          total: ReportCountervaluesEnum.Countervalue,
          ti: curr.ti.totalCountervalues.countervalue || 0,
          te: curr.te.totalCountervalues.countervalue || 0,
        };
        const customsDutiesCountervalue = {
          total: ReportCountervaluesEnum.CustomsCountervalue,
          ti: curr.ti.totalCountervalues.customsDutiesCountervalue || 0,
          te: curr.te.totalCountervalues.customsDutiesCountervalue || 0,
        };
        const dutiesCountervaluelue = {
          total: ReportCountervaluesEnum.DutiesCountervalue,
          ti: curr.ti.totalCountervalues.dutiesCountervalue || 0,
          te: curr.te.totalCountervalues.dutiesCountervalue || 0,
        };
        const parcelbyMonthObject = {
          date: curr.date,
          totals: [countervalue, customsDutiesCountervalue, dutiesCountervaluelue],
        };

        acc.push(parcelbyMonthObject);
        return acc;
      }, [] as ParcelCountervalueTotalsByMonthType[]) ?? [],
    [data],
  );

  const rows = useMemo<GridRowModel[]>(() => {
    return parcelTotalByMonth.reduce((acc, curr) => {
      acc = acc || [];
      if (!acc.length) {
        return [
          {
            total: curr.totals[0].total,
            ti: curr.totals[0].ti,
            te: curr.totals[0].te,
          },
          {
            total: curr.totals[1].total,
            ti: curr.totals[1].ti,
            te: curr.totals[1].te,
          },
          {
            total: curr.totals[2].total,
            ti: curr.totals[2].ti,
            te: curr.totals[2].te,
          },
        ];
      } else {
        return [
          {
            total: curr.totals[0].total,
            ti: acc[0].ti + curr.totals[0].ti,
            te: acc[0].te + curr.totals[0].te,
          },
          {
            total: curr.totals[1].total,
            ti: acc[1].ti + curr.totals[1].ti,
            te: acc[1].te + curr.totals[1].te,
          },
          {
            total: curr.totals[2].total,
            ti: acc[2].ti + curr.totals[2].ti,
            te: acc[2].te + curr.totals[2].te,
          },
        ];
      }
    }, [] as GridRowModel[]);
  }, [parcelTotalByMonth]);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'total',
        headerName: '',
        flex: 1,
        minWidth: 80,
        filterable: true,
        sortable: true,
        valueFormatter: function ({ value }: GridValueFormatterParams) {
          switch (value) {
            case ReportCountervaluesEnum.Countervalue:
              return 'Totale valore';
            case ReportCountervaluesEnum.CustomsCountervalue:
              return 'Totale Diritti';
            case ReportCountervaluesEnum.DutiesCountervalue:
              return 'Totale Dazi';
            default:
              break;
          }
        },
      },
      {
        field: 'ti',
        headerName: 'Temporanee Importazioni (€)',
        minWidth: 200,
        filterable: true,
        sortable: true,
        valueFormatter: function ({ value }: GridValueFormatterParams) {
          return value.toLocaleString();
        },
      },
      {
        field: 'te',
        headerName: 'Temporanee Esportazioni (€)',
        minWidth: 200,
        filterable: true,
        sortable: true,
        valueFormatter: function ({ value }: GridValueFormatterParams) {
          return value.toLocaleString();
        },
      },
    ];
  }, []);

  return (
    <ReportRow
      loading={loading}
      title={title}
      table={
        <DataGridPremium
          density="compact"
          getRowId={(row) => row.total}
          rows={rows || []}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            footer: CustomToolbar,
          }}
        />
      }
      chart={<ParcelCounterTotalChart data={parcelTotalByMonth} title={chartTitle} />}
    />
  );
}

export default function ParcelReportPage(): JSX.Element {
  const [interval, setInterval] = useState<Date[]>([]);

  const {
    data,
    isFetching: loading,
    error,
  } = useReadParcelReportQuery(
    { start: formatISODate(interval[0] ?? new Date()), end: formatISODate(interval[1] ?? new Date()) },
    { skip: !interval[0] },
  );

  const {
    data: htsData,
    isFetching: htsLoading,
    error: htsError,
  } = useReadParcelHtsReportQuery(
    { start: formatISODate(interval[0] ?? new Date()), end: formatISODate(interval[1] ?? new Date()) },
    { skip: !interval[0] },
  );

  const statusColumn = useStatusColDef();

  const rowStatusColumns = useMemo<GridColDef[]>(
    () => [
      {
        ...statusColumn,
        headerName: '',
        flex: 1,
        minWidth: 100,
        filterable: true,
        sortable: true,
      },
      {
        field: 'ti',
        headerName: 'Temporanee Importazioni',
        flex: 1,
        minWidth: 190,
        filterable: true,
        sortable: true,
      },
      {
        field: 'te',
        headerName: 'Temporanee Esportazioni',
        flex: 1,
        minWidth: 190,
        filterable: true,
        sortable: true,
      },
    ],
    [statusColumn],
  );

  const parcelByMonth = useMemo<ParcelStatusesByMonthType[]>(
    () =>
      data?.reduce((acc, curr) => {
        const tiByStatus = curr.ti.totalParcels.map((r) => ({ status: r.status, ti: r.value, te: 0 }));
        const teByStatus = curr.te.totalParcels.map((r) => ({ status: r.status, te: r.value, ti: 0 }));

        const parcelByMonth = [...tiByStatus, ...teByStatus].reduce((acc2, curr2) => {
          const index = acc2.findIndex((s) => s.status === curr2.status);
          if (index > -1) {
            acc2[index] = { ...acc2[index], ti: acc2[index].ti + curr2.ti, te: acc2[index].te + curr2.te };
          } else {
            acc2.push(curr2);
          }
          return acc2;
        }, [] as ParcelStatusesValuesType[]);

        const parcelbyMonthObject = {
          date: curr.date,
          values: parcelByMonth,
        };

        acc.push(parcelbyMonthObject);
        return acc;
      }, [] as ParcelStatusesByMonthType[]) ?? [],
    [data],
  );

  const rows = useMemo<GridRowModel[]>(() => {
    const map = parcelByMonth?.reduce((map, month) => {
      return month.values.reduce((map, values) => {
        const data: ParcelStatusesValuesType = map.get(values.status) ?? { status: values.status, ti: 0, te: 0 };
        data.ti = data.ti + values.ti;
        data.te = data.te + values.te;
        map.set(values.status, data);
        return map;
      }, map);
    }, new Map<number, ParcelStatusesValuesType>());

    return map ? [...map.values()] : [];
  }, [parcelByMonth]);

  const chartTitleInterval = useMemo(
    () => `(${formatISODate(interval[0] ?? new Date())} - ${formatISODate(interval[1] ?? new Date())})`,
    [interval],
  );

  return (
    <ReportLayout
      breadcrumbs={breadcrumbs}
      title={'Report Archivio Regimi Doganali'}
      error={error ?? htsError}
      onIntervalChange={setInterval}
    >
      <ReportRow
        loading={loading}
        title={`Pratiche per Stato`}
        table={
          <DataGridPremium
            density="compact"
            getRowId={(row) => row.status}
            rows={rows}
            columns={rowStatusColumns}
            disableRowSelectionOnClick
            slots={{
              footer: CustomToolbar,
            }}
          />
        }
        chart={<ParcelStatusChart data={parcelByMonth} title={`Pratiche per Stato ${chartTitleInterval}`} />}
      />
      <ParcelReportCounterTotalRow
        data={data}
        loading={loading}
        title="Totali Controvalori (€)"
        chartTitle={`Totali Controvalori (€) ${chartTitleInterval}`}
      />
      <ParcelReportTotalRow
        data={data}
        type={'ti'}
        loading={loading}
        title="Totali Valute T.I."
        chartTitle={`Totali Valute T.I. ${chartTitleInterval}`}
      />
      <ParcelReportTotalRow
        data={data}
        type={'te'}
        loading={loading}
        title="Totali Valute T.E."
        chartTitle={`Totali Valute T.E. ${chartTitleInterval}`}
      />
      <ReportRow
        title="Quantità e valori HTS"
        loading={htsLoading}
        table={
          <DataGrid
            rows={htsData ?? []}
            columns={[
              {
                field: 'hts',
                headerName: 'HTS',
                minWidth: 180,
                flex: 1,
              },
              {
                field: 'closedItemsQuantity',
                headerName: 'Q.tà Item restituiti (chiusi)',
                flex: 1,
                type: 'number',
              },
              {
                field: 'openItemsQuantity',
                headerName: 'Q.tà Item non restituiti (aperti)',
                flex: 1,
                type: 'number',
              },
              {
                field: 'closedItemsCountervalue',
                headerName: 'Item restituiti (chiusi) (€)',
                flex: 1,
                type: 'number',
              },
              {
                field: 'openItemsCountervalue',
                headerName: 'Item non restituiti (aperti) (€)',
                flex: 1,
                type: 'number',
              },
            ]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'closedItemsQuantity', sort: 'desc' }],
              },
            }}
            getRowId={(row) => row.hts}
            disableRowGrouping
            disableAggregation
            disableColumnFilter
          />
        }
      ></ReportRow>
    </ReportLayout>
  );
}
