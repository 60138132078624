import React from 'react';
import { AppUserListGrid } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import { userSection } from '../sections';

const breadcrumbs = [{ title: userSection.title }];

export default function UserListPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <AppUserListGrid appId={import.meta.env.VITE_APP_ID as string} />
      </Layout>
    </AuthGuard>
  );
}
