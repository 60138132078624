import React, { useMemo, useState } from 'react';

import {
  DataGridPremium,
  GridColDef,
  GridRowModel,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';
import PaperworkStatusChart from '../../components/Charts/PaperworkStatusChart';
import PaperworkTypeChart from '../../components/Charts/PaperworkTypeChart';

import ReportLayout, { ReportRow } from '../../components/Layout/ReportLayout';
import { PaperworkReport } from '../../entities/Report';
import { usePaperworkStatusColDef } from '../../hooks/usePaperworkStatusColDef';
import { usePaperworkTypeColDef } from '../../hooks/usePaperworkTypeColDef';
import { useReadPaperworkStatusListQuery } from '../../services/paperworkStatusApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';
import { useReadPaperworkReportQuery } from '../../services/reportApi';

import { formatISODate } from '../../utils';
import { PaperworkSection } from '../sections';

const breadcrumbs = [{ title: PaperworkSection.title, url: PaperworkSection.url }];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function PaperworkReportPage(): JSX.Element {
  const [interval, setInterval] = useState<Date[]>([]);

  const {
    data,
    isFetching: loading,
    error,
  } = useReadPaperworkReportQuery(
    { start: formatISODate(interval[0] ?? new Date()), end: formatISODate(interval[1] ?? new Date()) },
    { skip: !interval[0] },
  );

  const statusColumn = usePaperworkStatusColDef();
  const typeColumn = usePaperworkTypeColDef();
  const { data: statusData } = useReadPaperworkStatusListQuery();
  const { data: typeData } = useReadPaperworkTypeListQuery();

  const rowStatusColumns = useMemo<GridColDef[]>(
    () => [
      {
        ...statusColumn,
        headerName: '',
        flex: 1,
        minWidth: 100,
        filterable: true,
        sortable: true,
      },
      {
        field: 'value',
        headerName: 'Numero pratiche',
        flex: 1,
        minWidth: 100,
        filterable: true,
        sortable: true,
      },
    ],
    [statusColumn],
  );

  const rowTypeColumns = useMemo<GridColDef[]>(
    () => [
      {
        ...typeColumn,
        headerName: '',
        flex: 1,
        minWidth: 100,
        filterable: true,
        sortable: true,
      },
      {
        field: 'value',
        headerName: 'Numero pratiche',
        flex: 1,
        minWidth: 100,
        filterable: true,
        sortable: true,
      },
    ],
    [typeColumn],
  );

  const paperworkByMonth = useMemo<PaperworkReport[]>(
    () =>
      data?.map((v) => ({
        date: v.date,
        paperworksByStatus: v.paperworksByStatus,
        paperworksByType: v.paperworksByType,
      })) ?? [],
    [data],
  );

  const statusRows = useMemo<GridRowModel[]>(() => {
    if (!statusData || !statusData.list) return [];
    return (
      data?.reduce(
        (acc, curr) => {
          curr.paperworksByStatus.forEach((v) => {
            const index = acc.findIndex((s: GridRowModel) => s.status === v.status);
            if (index > -1) {
              acc[index] = { status: v.status, value: acc[index].value + v.value };
            }
          });
          return acc;
        },
        [...statusData.list.map((v) => ({ status: v.id, value: 0 }))] as GridRowModel[],
      ) || []
    );
  }, [data, statusData]);

  const typeRows = useMemo<GridRowModel[]>(() => {
    if (!typeData || !typeData.list) return [];
    return (
      data?.reduce(
        (acc, curr) => {
          curr.paperworksByType.forEach((v) => {
            const index = acc.findIndex((s: GridRowModel) => s.type === v.type);
            if (index > -1) {
              acc[index] = { type: v.type, value: acc[index].value + v.value };
            }
          });
          return acc;
        },
        [...typeData.list.map((v) => ({ type: v.id, value: 0 }))] as GridRowModel[],
      ) || []
    );
  }, [data, typeData]);

  const chartTitleInterval = useMemo(
    () => `(${formatISODate(interval[0] ?? new Date())} - ${formatISODate(interval[1] ?? new Date())})`,
    [interval],
  );

  return (
    <ReportLayout
      breadcrumbs={breadcrumbs}
      title={'Report Archivio Customs'}
      error={error}
      onIntervalChange={setInterval}
    >
      <ReportRow
        loading={loading}
        title={`Pratiche per Stato`}
        table={
          <DataGridPremium
            density="compact"
            getRowId={(row) => row.status}
            rows={statusRows}
            columns={rowStatusColumns}
            disableRowSelectionOnClick
            slots={{
              footer: CustomToolbar,
            }}
          />
        }
        chart={<PaperworkStatusChart data={paperworkByMonth} title={`Pratiche per Stato ${chartTitleInterval}`} />}
      />
      <ReportRow
        loading={loading}
        title={`Pratiche per Tipo`}
        height={510}
        table={
          <DataGridPremium
            density="compact"
            getRowId={(row) => row.type}
            rows={typeRows}
            columns={rowTypeColumns}
            disableRowSelectionOnClick
            slots={{
              footer: CustomToolbar,
            }}
          />
        }
        chart={<PaperworkTypeChart data={paperworkByMonth} title={`Pratiche per Tipo ${chartTitleInterval}`} />}
      />
    </ReportLayout>
  );
}
