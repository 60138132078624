import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterOperator } from '@top-solution/microtecnica-utils';
import { format } from 'date-fns';
import jsonexport from 'jsonexport/dist';
import Button from '@mui/material/Button';
import { TrayArrowDownIcon } from '../../components/Icons';
import { useReadPaperworkListQuery } from '../../services/paperworkApi';
import { useReadPaperworkStatusListQuery } from '../../services/paperworkStatusApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';

interface PaperworkExportButtonProps {
  sort: string[];
  filters: {
    field: string;
    operator: FilterOperator;
    value: string;
  }[];
}

const headerMapObj = {
  id: 'Nr Pratica',
  createUser: 'Autore Pratica',
  createDatetime: 'Data Inserimento Pratica',
  applicant: 'Nome Richiedente',
  requestDate: 'Data Richiesta',
  type: 'Tipologia Pratica',
  parcel: 'Regime Doganale collegato',
  license: 'Riferimento Licenza',
  status: 'Stato Richiesta',
  mtReferenceNo: 'Protocollo MT',
  mtReferenceNoDate: 'Data Protocollo MT',
  customsProvisionNo: 'Protocollo Provvedimento Dogana',
  customsProvisionNoDate: 'Data Provvedimento Dogana',
  customsDuties: 'Diritti Doganali',
  sanctions: 'Sanzioni',
  attachments: 'Nr Allegati',
  note: 'Note',
} as unknown as Record<string, string>;

export default function PaperworkExportButton(props: PaperworkExportButtonProps): JSX.Element {
  const { sort, filters } = props;
  const { data: statusData } = useReadPaperworkStatusListQuery();
  const { data: typeData } = useReadPaperworkTypeListQuery();
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const readParcelListParams = useMemo(
    () => ({
      limit: 10000,
      offset: 0,
      sort,
      filters,
    }),
    [filters, sort],
  );

  const { data: paperworkList, isFetching } = useReadPaperworkListQuery(readParcelListParams, {
    skip: !downloadingCsv,
  });

  const handleExport = useCallback((): void => {
    if (!paperworkList) {
      return;
    }

    // Flatting the items on the parcel object, generating a row for each item with the corresponding parcel
    // eslint-disable-next-line @typescript-eslint/ban-types
    const rows = [];

    for (const paperwork of paperworkList.data) {
      const {
        id,
        createUser,
        createDatetime,
        applicant,
        requestDate,
        type,
        parcel,
        license,
        status,
        mtReferenceNo,
        mtReferenceNoDate,
        customsProvisionNo,
        customsProvisionNoDate,
        customsDuties,
        sanctions,
        attachments,
        note,
      } = paperwork;

      const row = {
        id,
        createUser,
        createDatetime,
        applicant,
        requestDate,
        type: typeData?.map[type].name || type,
        parcel: parcel ? `#${parcel.id}` : '',
        license,
        status: statusData?.map[status]?.name || status,
        mtReferenceNo,
        mtReferenceNoDate,
        customsProvisionNo,
        customsProvisionNoDate,
        customsDuties,
        sanctions,
        attachments: attachments?.length,
        note,
      };

      rows.push(row);
    }

    const options = {
      rowDelimiter: ';',
      mapHeaders: (header: string) => {
        return headerMapObj[header] || header;
      },
      headers: ['id', 'createUser'],
    };

    jsonexport(rows, options, function (err: Error, csv: string) {
      // eslint-disable-next-line no-console
      if (err) return console.error(err);

      const blob = new Blob([csv], { type: 'text/csv' });
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute(
        'download',
        `customs-repository-archivio-customs-${format(new Date(), 'yyyy-MM-dd-hh-mm-ss')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      setDownloadingCsv(false);
    });
  }, [paperworkList, statusData?.map, typeData?.map]);

  useEffect(() => {
    if (downloadingCsv && !isFetching) {
      handleExport();
    }
  }, [downloadingCsv, handleExport, isFetching]);

  return (
    <Button size="small" onClick={() => setDownloadingCsv(true)} startIcon={<TrayArrowDownIcon />}>
      Esporta
    </Button>
  );
}
