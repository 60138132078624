import React, { useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import { PaperworkStatusEnum } from '../../entities/PaperworkStatus';
import { PaperworkReport } from '../../entities/Report';
import { useReadPaperworkStatusListQuery } from '../../services/paperworkStatusApi';
import { ReportBarChart, ReportBarChartColors } from './ReportBarChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface PaperworkStatusChartProps {
  data: PaperworkReport[];
  title: string;
}

function PaperworkStatusChart(props: PaperworkStatusChartProps) {
  const { data, title } = props;
  const { data: statusData, isLoading } = useReadPaperworkStatusListQuery();

  const labels = data.map((d) => format(parseISO(d.date), 'LLLL', { locale: it }));

  const chartData = useMemo(() => {
    const statusMap: Record<string, number> = {};
    statusData?.list.forEach((status) => (statusMap[status.name] = status.id));
    return {
      labels,
      datasets: [
        {
          label: 'Annullate',
          data: data.map(
            (d) => d.paperworksByStatus.find((v) => v.status === statusMap[PaperworkStatusEnum.Cancelled])?.value,
          ),
          backgroundColor: ReportBarChartColors[0],
        },
        {
          label: 'In Attesa',
          data: data.map(
            (d) => d.paperworksByStatus.find((v) => v.status === statusMap[PaperworkStatusEnum.Pending])?.value,
          ),
          backgroundColor: ReportBarChartColors[1],
        },
        {
          label: 'Rilasciate',
          data: data.map(
            (d) => d.paperworksByStatus.find((v) => v.status === statusMap[PaperworkStatusEnum.Released])?.value,
          ),
          backgroundColor: ReportBarChartColors[2],
        },
        {
          label: 'Inviate',
          data: data.map(
            (d) => d.paperworksByStatus.find((v) => v.status === statusMap[PaperworkStatusEnum.Sent])?.value,
          ),
          backgroundColor: ReportBarChartColors[3],
        },
      ],
    };
  }, [data, labels, statusData?.list]);

  if (isLoading) {
    return null;
  }

  return <ReportBarChart title={title} chartData={chartData} loading={isLoading} />;
}

export default React.memo(PaperworkStatusChart);
