import { useCallback, useMemo } from 'react';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';

import { useReadSaleTypeListQuery } from '../services/saleTypeApi';

export function useSaleTypeColDef(): GridColDef {
  const { data } = useReadSaleTypeListQuery();

  const valueOptions = useMemo(() => data?.list.map((plant) => ({ value: plant.id, label: plant.name })), [data]);
  const valueFormatter = useCallback(
    ({ value }: GridValueFormatterParams) =>
      typeof value === 'number' ? data?.map[value]?.name || `⚠️ ${value}` : value,
    [data],
  );
  return {
    field: 'saleType',
    headerName: 'Tipologia Vendita',
    type: 'singleSelect',
    valueOptions,
    valueFormatter,
  };
}
