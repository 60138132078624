import { z } from 'zod';

export enum StatusEnum {
  Open = 'Aperta',
  Close = 'Chiusa',
  PartialDischarge = 'Scarico parziale',
}

export const StatusSchema = z.object({
  id: z.number(),
  name: z.nativeEnum(StatusEnum),
});

export type Status = z.infer<typeof StatusSchema>;
