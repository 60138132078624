import React, { useCallback, useMemo } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { PaperworkAddForm } from '../../entities/Paperwork';
import { PaperworkStatusEnum } from '../../entities/PaperworkStatus';
import { PaperworkTypeEnum } from '../../entities/PaperworkType';
import { UserRole } from '../../entities/User';
import { usePaperworkSchemaWithTypeCheck } from '../../hooks/usePaperworkSchemaWithTypeCheck';
import { usePaperworkStatus } from '../../hooks/usePaperworkStatus';
import { useCreatePaperworkMutation, useReadPaperworkQuery } from '../../services/paperworkApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';
import { formatISODate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { PaperworkSection } from '../sections';
import { PaperworkEditCard } from './PaperworkEditCard';

const title = 'Nuova pratica';
const breadcrumbs = [{ title: PaperworkSection.title, url: PaperworkSection.url }, { title }];

const defaultValues: DefaultValues<PaperworkAddForm> = {
  attachments: [],
};

export default function PaperworkAddPage(): JSX.Element {
  const navigate = useNavigate();
  const [create, createStatus] = useCreatePaperworkMutation();
  const { PaperworkAddSchemaWithTypeCheck, statusIsLoading, typeIsLoading } = usePaperworkSchemaWithTypeCheck();
  const paperworkType = useReadPaperworkTypeListQuery();
  const { id: duplicateId } = useParams();
  const {
    data: sourcePaperwork,
    isFetching: sourcePaperworkLoading,
    error: sourcePaperworkError,
  } = useReadPaperworkQuery(duplicateId ?? '', { skip: duplicateId === undefined });
  const { getPaperworkStatus } = usePaperworkStatus();

  const handleSubmit = useCallback(
    async (data: PaperworkAddForm) => {
      const isDDTI = PaperworkTypeEnum.DTTI === paperworkType.data?.map[data.type].name;

      let extensionDate = data.extensionDate ? formatISODate(data.extensionDate) : undefined;
      if (getPaperworkStatus(data) !== PaperworkStatusEnum.Released) {
        extensionDate = undefined;
      }

      await create({
        ...data,
        attachments: (data.attachments ?? []).map((a) => a.id),
        requestDate: formatISODate(data.requestDate),
        mtReferenceNoDate: formatISODate(data.mtReferenceNoDate),
        customsProvisionNoDate: data.customsProvisionNoDate ? formatISODate(data.customsProvisionNoDate) : undefined,
        license: isDDTI ? data.license : '',
        parcelId: !isDDTI && data.parcelId ? parseInt(data.parcelId) : undefined,
        extensionDate,
      }).unwrap();
      navigate('..');
    },
    [create, getPaperworkStatus, navigate, paperworkType.data?.map],
  );

  const createDefaultValues: DefaultValues<PaperworkAddForm> | undefined = useMemo(() => {
    if (duplicateId === undefined) {
      return defaultValues;
    }

    if (!sourcePaperwork) {
      return undefined;
    }

    return {
      ...sourcePaperwork,
      requestDate: new Date(sourcePaperwork.requestDate),
      mtReferenceNoDate: new Date(sourcePaperwork.mtReferenceNoDate),
      customsProvisionNoDate: sourcePaperwork.customsProvisionNoDate
        ? new Date(sourcePaperwork.customsProvisionNoDate)
        : undefined,
      extensionDate: sourcePaperwork.extensionDate ? new Date(sourcePaperwork.extensionDate) : undefined,
      attachments: [],
      parcelId: sourcePaperwork.parcel?.id ? `${sourcePaperwork.parcel?.id}` : undefined,
    };
  }, [duplicateId, sourcePaperwork]);

  return (
    <AuthGuard
      authorizeRole={(role) => role === UserRole.WRITE_PARCELS || role === UserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Layout
        breadcrumbs={breadcrumbs}
        disableGutters
        sx={{ p: 1 }}
        title={title}
        inProgress={statusIsLoading || typeIsLoading || sourcePaperworkLoading}
        error={sourcePaperworkError}
      >
        {createDefaultValues && (
          <PaperworkEditCard
            title={title}
            resolver={PaperworkAddSchemaWithTypeCheck}
            defaultValues={createDefaultValues}
            isLoading={createStatus.isLoading}
            error={createStatus.error}
            onSubmit={handleSubmit}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
