import { z } from 'zod';

enum ShipmentReasonEnum {
  MRO = 'MRO',
  CRM = 'CRM',
  Purchase = 'Acquisto',
  CL = 'CL',
  Coarse = 'Grezzi',
}

export const ShipmentReasonSchema = z.object({
  id: z.number(),
  name: z.nativeEnum(ShipmentReasonEnum),
});

export type ShipmentReason = z.infer<typeof ShipmentReasonSchema>;
