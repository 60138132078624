import { useMemo } from 'react';
import { z } from 'zod';
import { PaperworkAddFormSchema } from '../entities/Paperwork';
import { PaperworkStatusEnum } from '../entities/PaperworkStatus';
import { PaperworkTypeEnum } from '../entities/PaperworkType';
import { useReadPaperworkStatusListQuery } from '../services/paperworkStatusApi';
import { useReadPaperworkTypeListQuery } from '../services/paperworkTypeApi';

export function usePaperworkSchemaWithTypeCheck() {
  const { data: typeData, isLoading: typeIsLoading } = useReadPaperworkTypeListQuery();
  const { data: statuseData, isLoading: statusIsLoading } = useReadPaperworkStatusListQuery();

  const PaperworkAddSchemaWithTypeCheck = useMemo(() => {
    if (typeIsLoading || statusIsLoading) {
      return null;
    }
    return PaperworkAddFormSchema.superRefine((val, ctx) => {
      if (PaperworkTypeEnum.DTTI === typeData?.map[val.type].name) {
        if (val.license === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio',
            path: ['license'],
          });
        }
      } else {
        if (val.parcelId === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio',
            path: ['parcelId'],
          });
        }
      }
      if (val.customsProvisionNo !== undefined && val.customsProvisionNo !== null) {
        if (!val.customsProvisionNoDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio con protocollo dogana',
            path: ['customsProvisionNoDate'],
          });
        }
        if (val.customsDuties === undefined || val.customsDuties === null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Campo obbligatorio con protocollo dogana',
            path: ['customsDuties'],
          });
        }
      }
      if (PaperworkTypeEnum.Extension === typeData?.map[val.type].name) {
        if (PaperworkStatusEnum.Released === statuseData?.map[val.status].name) {
          if (!val.extensionDate) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Campo obbligatorio con tipo "Proroga" e stato "Rilasciata"',
              path: ['extensionDate'],
            });
          }
        }
      }
    });
  }, [typeIsLoading, statusIsLoading, typeData?.map, statuseData?.map]);

  return { PaperworkAddSchemaWithTypeCheck, typeIsLoading, statusIsLoading };
}
