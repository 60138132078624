import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ServerErrorBox, Header, Breadcrumb } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import Container, { ContainerProps } from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { FullPageProgress } from '../Progress';

export interface AppLayoutProps extends ContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: React.ReactNode;
  // maxWidth?: false | Breakpoint | undefined;
}

const APP_NAME = import.meta.env.VITE_NAME as string;

export default function Layout(props: AppLayoutProps): JSX.Element {
  const { children, title, breadcrumbs, inProgress, maxWidth, error, progressIndicator, sx, ...containerProps } = props;
  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header logo={{ title: APP_NAME }} position="fixed" breadcrumbs={breadcrumbs} />
      <Toolbar />
      <Container
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          py: 4,
          ...sx,
        }}
        maxWidth={error ? 'sm' : maxWidth}
        {...containerProps}
      >
        {inProgress ? progressIndicator || <FullPageProgress /> : error ? <ServerErrorBox error={error} /> : children}
      </Container>
    </>
  );
}
