import React from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card, { CardProps } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { SaveIcon } from '../../components/Icons';
import { PaperworkAddForm } from '../../entities/Paperwork';
import PaperworkEditForm from './PaperworkEditForm';

type PaperworkEditCardProps = Omit<CardProps, 'onSubmit'> & {
  title: string;
  isLoading: boolean;
  defaultValues: DefaultValues<PaperworkAddForm>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolver: any;
  error: ZodError | ApiError | Error | undefined;
  onSubmit: (data: PaperworkAddForm) => void;
};

export function PaperworkEditCard(props: PaperworkEditCardProps): JSX.Element {
  const { title, defaultValues, resolver, onSubmit, isLoading, error, ...cardProps } = props;

  const form = useForm<PaperworkAddForm>({
    defaultValues,
    resolver: zodResolver(resolver),
  });

  const { handleSubmit } = form;

  return (
    <Card {...cardProps}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h4" marginBottom={2}>
            {title}
          </Typography>
        </Box>
        <PaperworkEditForm form={form} />
        {error && <ErrorAlert error={error} />}
      </CardContent>
      <CardActions sx={{ px: 2, pb: 2 }}>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="secondary" component={Link} to=".." sx={{ mr: 1 }}>
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
