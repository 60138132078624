import React, { useCallback, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  useGridApiRef,
  MuiEvent,
  GridPinnedColumns,
  MuiBaseEvent,
} from '@mui/x-data-grid-premium';
import { AddIcon, CopyIcon, DeleteIcon, EditIcon } from '../../components/Icons';
import { emptyTI, emptyTE, Item, TEForm, TIForm } from '../../entities/Item';
import { RegimeEnum } from '../../entities/Parcel';
import { useItemGridColumns } from '../../hooks/useItemGridColumns';
import { parseISODate } from '../../utils';
import { TemporaryExportDialog } from './TemporaryExportDialog';
import { TemporaryImportDialog } from './TemporaryImportDialog';

const StyledBox = styled(Box)(({ theme }) => ({
  height: 400,
  width: '100%',
  position: 'relative',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const pinnedColumns: GridPinnedColumns = { right: ['actions'] };

interface ItemsGridProps {
  value: Item[];
  regime?: RegimeEnum;
  onChange: (value: Item[]) => void;
  error?: FieldError;
}

export default function ItemsGrid(props: ItemsGridProps): JSX.Element {
  const { value, onChange, regime, error } = props;
  const [dialogInitialValues, setDialogInitialValues] = useState<TIForm | TEForm | null>(null);

  const apiRef = useGridApiRef();
  const itemColumns = useItemGridColumns({ regime: regime });

  const muiDefaultPrevented = (_: unknown, event?: MuiEvent<MuiBaseEvent>) => {
    if (event) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleAddClick = useCallback(() => {
    if (regime === RegimeEnum.TI) {
      setDialogInitialValues(emptyTI);
    } else if (regime === RegimeEnum.TE) {
      setDialogInitialValues(emptyTE);
    }
  }, [regime]);

  const handleAddDialogClose = useCallback(
    (data?: Item) => {
      if (data) {
        if (dialogInitialValues?.sn && dialogInitialValues.pn) {
          // EDIT
          onChange(
            value.map((record) => {
              if (record.pn === dialogInitialValues.pn && record.sn === dialogInitialValues.sn) {
                return data;
              }
              return record;
            }),
          );
        } else {
          //ADD
          onChange([...value, data]);
        }
      }
      setDialogInitialValues(null);
    },
    [dialogInitialValues, onChange, value],
  );

  const handleEditClick = useCallback(
    ({ row }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        const emptyValue = regime === RegimeEnum.TI ? emptyTI : regime === RegimeEnum.TE ? emptyTE : null;
        setDialogInitialValues({
          ...emptyValue,
          ...row,
          awbDate: row.awbDate ? parseISODate(row.awbDate) : null,
          exAReg3Date: row.exAReg3Date ? parseISODate(row.exAReg3Date) : null,
          billImAReg6Date: row.billImAReg6Date ? parseISODate(row.billImAReg6Date) : null,
          mrnDate: row.mrnDate ? parseISODate(row.mrnDate) : null,
        });
      },
    [regime],
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        onChange(value.filter((item) => item.sn !== row.sn || item.pn !== row.pn));
      },
    [onChange, value],
  );

  const handleCopyClick = useCallback(
    ({ row }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        const emptyValue = regime === RegimeEnum.TI ? emptyTI : regime === RegimeEnum.TE ? emptyTE : null;
        setDialogInitialValues({
          ...emptyValue,
          ...row,
          sn: '',
          awbDate: row.awbDate ? parseISODate(row.awbDate) : null,
          exAReg3Date: row.exAReg3Date ? parseISODate(row.exAReg3Date) : null,
          billImAReg6Date: row.billImAReg6Date ? parseISODate(row.billImAReg6Date) : null,
          mrnDate: row.mrnDate ? parseISODate(row.mrnDate) : null,
          customsDutiesCountervalue: row.customsDutiesCountervalue ?? null,
          dutiesCountervalue: row.dutiesCountervalue ?? null,
        });
      },
    [regime],
  );

  const columns = useMemo<GridColDef[]>(() => {
    const editColumn = {
      field: 'actions',
      type: 'actions',
      renderHeader: () => (
        <IconButton color="primary" onClick={handleAddClick}>
          <AddIcon />
        </IconButton>
      ),
      width: 110,
      cellClassName: 'actions',
      getActions: (row: GridRowParams) => [
        <GridActionsCellItem
          key="editIcon"
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(row)}
          color="inherit"
        />,
        <GridActionsCellItem
          key="copyIcon"
          icon={<CopyIcon />}
          label="Copy"
          className="textPrimary"
          onClick={handleCopyClick(row)}
          color="inherit"
        />,
        <GridActionsCellItem
          key="deleteIcon"
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(row)}
          color="inherit"
        />,
      ],
    };
    return [editColumn, ...itemColumns];
  }, [itemColumns, handleAddClick, handleEditClick, handleCopyClick, handleDeleteClick]);

  return (
    <StyledBox>
      <DataGridPremium
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
        getRowId={(row) => `${row.sn}~${row.pn}`}
        editMode="row"
        rows={value}
        columns={columns}
        apiRef={apiRef}
        onRowEditStart={muiDefaultPrevented}
        onRowEditStop={muiDefaultPrevented}
        pinnedColumns={pinnedColumns}
        disableAggregation
        disableRowGrouping
        slotProps={{
          cell: {
            onBlur: muiDefaultPrevented,
          },
        }}
        sx={{ borderColor: error && 'error.main' }}
      />
      {error && (
        <Typography className="Input-grid-error" variant="caption" color="error.main">
          {error.message}
        </Typography>
      )}
      {regime === RegimeEnum.TI && dialogInitialValues && (
        <TemporaryImportDialog
          initialValues={dialogInitialValues as TIForm}
          editMode={Boolean(dialogInitialValues.sn && dialogInitialValues.pn)}
          open={true}
          onClose={handleAddDialogClose}
          otherItems={value.filter(({ pn, sn }) => dialogInitialValues.pn !== pn || dialogInitialValues.sn != sn)}
        />
      )}
      {regime === RegimeEnum.TE && dialogInitialValues && (
        <TemporaryExportDialog
          initialValues={dialogInitialValues as TEForm}
          editMode={Boolean(dialogInitialValues.sn && dialogInitialValues.pn)}
          open={true}
          onClose={handleAddDialogClose}
          otherItems={value.filter(({ pn, sn }) => dialogInitialValues.pn !== pn || dialogInitialValues.sn != sn)}
        />
      )}
    </StyledBox>
  );
}
