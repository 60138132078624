import { z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { ParcelSchema } from './Parcel';

export const PaperworkSchema = z.object({
  attachments: z.array(AttachmentSchema).min(1, 'Campo obbligatorio').nullable(),
  applicant: z.string().min(1, 'Campo obbligatorio'),
  requestDate: z.string().min(1, 'Campo obbligatorio'),
  createDatetime: z.string(),
  createUser: z.string(),
  mtReferenceNo: z.string().min(1, 'Campo obbligatorio'),
  mtReferenceNoDate: z.string().min(1, 'Campo obbligatorio'),
  customsProvisionNo: z.string().optional().nullable(),
  customsProvisionNoDate: z.string().optional().nullable(),
  extensionDate: z.string().optional().nullable(),
  id: z.number(),
  note: z.string().max(512).optional(),
  customsDuties: z.number().optional().nullable(),
  sanctions: z.number().optional().nullable(),
  license: z.string().optional(),
  parcel: ParcelSchema.omit({ items: true, attachments: true }).nullable(),
  type: z.number(),
  status: z.number(),
});

export type Paperwork = z.infer<typeof PaperworkSchema>;

export const PaperworkAddFormSchema = PaperworkSchema.omit({
  id: true,
  createUser: true,
  createDatetime: true,
  parcel: true,
  extensionDate: true,
}).extend({
  requestDate: z.date(),
  mtReferenceNoDate: z.date(),
  customsProvisionNoDate: z.date().optional(),
  parcelId: z.string().optional(),
  extensionDate: z.date().optional(),
});

export type PaperworkAddForm = z.infer<typeof PaperworkAddFormSchema>;
