import secondary from '@mui/material/colors/blueGrey';
import primary from '@mui/material/colors/deepPurple';
import grey from '@mui/material/colors/grey';
import { itIT as coreItIT } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { itIT } from '@mui/x-data-grid-premium';

const baseThemeConfig: ThemeOptions = {
  palette: {
    primary,
    secondary,
    background: {
      default: grey[50],
    },
  },
};

export const theme = createTheme(baseThemeConfig, itIT, coreItIT);
