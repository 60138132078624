import { z } from 'zod';

export enum PaperworkStatusEnum {
  Pending = 'In attesa',
  Sent = 'Inviata',
  Released = 'Rilasciata',
  Cancelled = 'Annullata',
}

export const PaperworkStatusSchema = z.object({
  id: z.number(),
  name: z.nativeEnum(PaperworkStatusEnum),
});

export type PaperworkStatus = z.infer<typeof PaperworkStatusSchema>;
