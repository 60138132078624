import React, { useCallback, useMemo, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ParcelTotalsByMonthType } from '../../pages/Parcel/ParcelReportPage';
import { ReportBarChart, ReportBarChartColors } from './ReportBarChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ParcelTotalChartProps {
  data: ParcelTotalsByMonthType[];
  title: string;
  currencies: string[];
}

function ParcelTotalChart(props: ParcelTotalChartProps) {
  const { data, currencies, title } = props;
  const [selectedCurrency, setSelectedCurrency] = useState<string>(currencies[0]);

  const labels = data.map((d) => format(parseISO(d.date), 'LLLL', { locale: it }));

  const chartData = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: 'Totale Valore',
          data: data.map((v) => v.totals.find((t) => t.total === 'totalValues')?.[selectedCurrency]),
          backgroundColor: ReportBarChartColors[0],
        },
      ],
    };
  }, [data, labels, selectedCurrency]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const currency = event.target.value;
    setSelectedCurrency(currency);
  }, []);

  return (
    <>
      <Box sx={{ width: 200, marginBottom: 2 }}>
        <TextField
          className="currencySelect"
          value={selectedCurrency}
          onChange={handleChange}
          select
          size="small"
          label="Seleziona valuta"
          fullWidth
        >
          {currencies?.map((c) => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <ReportBarChart title={title} chartData={chartData} />
    </>
  );
}

export default React.memo(ParcelTotalChart);
