import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  CustomTableFooter,
  DataGrid,
  DataGridWrapper,
  muiFiltersToPagedRequestFilters,
  useCountryGridColDef,
  usePlantColDef,
  DeleteConfirmDialog,
  ErrorAlert,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, PagedRequestFilter, useAuth } from '@top-solution/microtecnica-utils';
import Button from '@mui/material/Button';
import { green, yellow } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPinnedColumns,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridSlotsComponentsProps,
  GridSortModel,
  GridValidRowModel,
  GridValueFormatterParams,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import ExpirationFormatted from '../../components/ExpirationFormatted';
import { AddIcon, ChartBarStackedIcon, DeleteIcon, EditIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { Attachment } from '../../entities/Attachment';
import { Item } from '../../entities/Item';
import { Paperwork } from '../../entities/Paperwork';
import { Parcel } from '../../entities/Parcel';
import { UserRole } from '../../entities/User';
import { useParcelStatus } from '../../hooks/useParcelStatus';
import { useRegimeColDef } from '../../hooks/useRegimeColDef';
import { useSaleTypeColDef } from '../../hooks/useSaleTypeColDef';
import { useShipmentReasonColDef } from '../../hooks/useShipmentReasonColDef';
import { useStatusColDef } from '../../hooks/useStatusColDef';
import { useReadParcelListQuery, useRemoveParcelMutation } from '../../services/parcelApi';
import { formatStringDate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { ParcelSection } from '../sections';
import { PaperworkLinkButton } from './PaperworkLinkButton';
import ParcelAccordion from './ParcelAccordion';
import ParcelExportButton from './ParcelExportButton';

const breadcrumbs = [{ title: ParcelSection.title }];
const pinnedColumns: GridPinnedColumns = { right: ['actions'] };

export default function ParcelListPage(): JSX.Element {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const { isAdmin, hasRole, roles } = useAuth();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'customsReferenceNoDate', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const [itemFilters, setItemFilters] = useState<PagedRequestFilter[]>([]);
  const [parcelToRemove, setParcelToRemove] = useState<Parcel | null>(null);
  const [removeParcel, removeParcelRequest] = useRemoveParcelMutation();
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);
  const { getParcelStatus } = useParcelStatus();

  const sort = useMemo(() => sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`), [sortModel]);

  const filters = useMemo(
    () => [...itemFilters, ...muiFiltersToPagedRequestFilters(filterModel.items)],
    [filterModel.items, itemFilters],
  );

  const readParcelListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize as number,
      offset: (paginationModel.pageSize as number) * paginationModel.page,
      sort,
      filters,
    }),
    [filters, paginationModel.page, paginationModel.pageSize, sort],
  );

  const ParcelList = useReadParcelListQuery(readParcelListParams, {
    skip: paginationModel.pageSize === undefined,
  });

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleItemFilters = useCallback(
    (value: PagedRequestFilter[]) => {
      setSortModel([{ field: 'customsReferenceNoDate', sort: 'desc' }]);
      setFilterModel({ items: [], logicOperator: GridLogicOperator.And });
      setPaginationModel({ page: 0 });
      setItemFilters(value);
    },
    [setPaginationModel],
  );

  const handleRemoveParcelkConfirm = useCallback(async () => {
    if (parcelToRemove) {
      await removeParcel(parcelToRemove).unwrap();
      setParcelToRemove(null);
    }
  }, [parcelToRemove, removeParcel]);

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setParcelToRemove(row as Parcel);
      },
    [],
  );

  const plantColumn = usePlantColDef();
  const countryColumn = useCountryGridColDef();
  const regimeColumn = useRegimeColDef();
  const saleTypeColumn = useSaleTypeColDef();
  const statusColumn = useStatusColDef();
  const shipmentReasonColumn = useShipmentReasonColDef();

  const rows = useMemo<GridRowModel[]>(
    () => ParcelList.data?.data.map((item) => ({ ...item })) || [],
    [ParcelList.data],
  );

  // console.log(rows);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'Nr pratica',
        type: 'number',
        width: 100,
        renderCell: ({ value }) => (
          <Button size="small" color="primary" component={Link} to={`./${value}`}>
            {value}
          </Button>
        ),
      },
      {
        field: 'createUser',
        headerName: 'Autore Pratica',
        width: 150,
      },
      {
        field: 'createDatetime',
        headerName: 'Data Inserimento Pratica',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
      },
      {
        ...regimeColumn,

        width: 50,
      },
      {
        ...saleTypeColumn,

        width: 150,
      },
      {
        ...plantColumn,
        width: 150,
      },
      {
        field: 'customsReferenceNo',
        headerName: 'Protocollo Dogana',
        width: 150,
        cellClassName: (params: GridCellParams<Parcel>) => `status-${getParcelStatus(params.row)}`,
      },
      {
        field: 'customsReferenceNoDate',
        headerName: 'Data Protocollo Dogana',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
      },
      {
        field: 'deadlineDate',
        headerName: 'Data Scadenza',
        width: 150,
        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
        renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => {
          const parcel = rows.find((p) => p.id === params.id);
          return parcel && <ExpirationFormatted parcel={parcel as Parcel} />;
        },
      },
      {
        ...statusColumn,
        width: 150,
      },
      {
        ...shipmentReasonColumn,

        width: 150,
      },
      {
        field: 'customerSupplier',
        headerName: 'Cliente/Fornitore',
        width: 150,
      },
      { ...countryColumn, width: 150, filterable: true, sortable: true, editable: false },
      {
        field: 'items',
        headerName: 'Nr Item',
        width: 100,
        filterable: false,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<Item[]>) => value?.length,
      },
      {
        field: 'attachments',
        headerName: 'Nr Allegati',
        width: 100,
        filterable: false,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<Attachment[]>) => value?.length,
      },
      {
        field: 'militaryLicense',
        headerName: 'Riferimento Licenza',
        width: 150,
      },
      {
        field: 'militaryLicenseIssueDate',
        headerName: 'Data Rilascio Licenza',
        width: 150,

        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
      },
      {
        field: 'militaryLicenseExpirationDate',
        headerName: 'Scadenza Licenza',
        width: 150,

        type: 'date',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => formatStringDate(value),
      },
      // {
      //   field: 'linkedPaperworkIds',
      //   headerName: 'Pratiche customs collegate',
      //   width: 250,
      //   filterable: false,
      //   sortable: false,
      //   valueFormatter: ({ value }: GridValueFormatterParams<number[]>) =>
      //     (value ?? []).map((id) => (
      //       <Button size="small" key={id} color="primary" component={Link} to={`/paperwork/${id}`}>
      //         {id}
      //       </Button>
      //     )),
      // },
      {
        field: 'paperworks',
        headerName: 'Pratiche customs collegate',
        width: 250,
        filterable: false,
        sortable: false,
        renderCell: ({ value }) =>
          value ? [...value].map((p: Paperwork) => <PaperworkLinkButton key={p.id} paperwork={p} />) : `—`,
        // valueFormatter: ({ value }) => (value ? value.map((p: Paperwork) => p?.id) : []),
      },
      {
        field: 'note',
        headerName: 'Note',
        width: 150,
        filterable: false,
        sortable: false,
      },
      ...(isAdmin || hasRole(UserRole.WRITE_PARCELS)
        ? [
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Azioni',
              renderHeader: () => (
                <Tooltip title="Aggiungi Pratica">
                  <IconButton color="primary" onClick={() => navigate('./add')} sx={{ mx: 1 }}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ),
              getActions: (params: GridRowParams) => {
                const parcel = params.row as Parcel;

                const hasPaperworks = Boolean(parcel.paperworks && parcel.paperworks.length > 0);

                const deleteTitle = hasPaperworks
                  ? 'Non è possibile eliminare Regimi Doganali con Pratiche Customs associate'
                  : 'Elimina';

                return [
                  <Tooltip key="edit" title={'Modifica'}>
                    <span>
                      <GridActionsCellItem
                        icon={<EditIcon />}
                        label={'Modifica'}
                        onClick={() => navigate(`./${params.row.id}/edit`)}
                      />
                    </span>
                  </Tooltip>,

                  <Tooltip key="delete" title={deleteTitle}>
                    <span>
                      <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={deleteTitle}
                        onClick={handleDeleteClick(params)}
                        disabled={hasPaperworks}
                      />
                    </span>
                  </Tooltip>,
                ];
              },
            },
          ]
        : []),
    ],
    [
      regimeColumn,
      saleTypeColumn,
      plantColumn,
      statusColumn,
      shipmentReasonColumn,
      countryColumn,
      isAdmin,
      hasRole,
      getParcelStatus,
      rows,
      navigate,
      handleDeleteClick,
    ],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <ParcelAccordion onFilter={handleItemFilters} onAccordionChange={setAccordionExpanded} />
        <DataGridWrapper offsetHeight={accordionExpanded ? 350 : 130}>
          <DataGrid
            density="compact"
            rows={rows}
            columns={columns}
            loading={ParcelList.isFetching}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={ParcelList.data?.total ?? 0}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            apiRef={apiRef}
            sessionStorageId={`ParcelDataGridSessionId${roles?.toString()}`}
            pinnedColumns={pinnedColumns}
            disableAggregation
            sx={{
              '& .status-Chiusa': {
                backgroundColor: green[300],
              },
              '& .status-Scarico.parziale': {
                backgroundColor: yellow[300],
              },
            }}
            slots={{
              footer: (props: NonNullable<GridSlotsComponentsProps['footer']>) => (
                <CustomTableFooter
                  customButtons={[
                    <ParcelExportButton key="export" sort={sort} filters={filters} />,
                    <Button
                      size="small"
                      key="report"
                      onClick={() => navigate(`/parcel/report`)}
                      startIcon={<ChartBarStackedIcon />}
                    >
                      Report
                    </Button>,
                  ]}
                  disableExportSelector={true}
                >
                  {props.children}
                </CustomTableFooter>
              ),
            }}
          />
        </DataGridWrapper>
        {parcelToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare la pratica nr° "${parcelToRemove.id}"?`}
            confirmText="confermo"
            open={Boolean(parcelToRemove)}
            inProgress={removeParcelRequest.isLoading}
            error={removeParcelRequest.error}
            onConfirm={() => handleRemoveParcelkConfirm()}
            onClose={() => {
              removeParcelRequest.reset();
              setParcelToRemove(null);
            }}
          />
        )}
      </Layout>
      {ParcelList.error && <ErrorAlert error={ParcelList.error} />}
    </AuthGuard>
  );
}
