import React, { useCallback, useMemo, useState } from 'react';
import { FilterOperator, PagedRequestParams } from '@top-solution/microtecnica-utils';
import { useDebounce } from 'rooks';
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useLazyReadParcelListQuery } from '../../services/parcelApi';

type ParcelAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<string, Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange' | 'css'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'required' | 'sx'>;

function ParcelAutocompleteComponent<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: ParcelAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<unknown>): JSX.Element {
  const { label, error, value, helperText, placeholder, required, sx, ...autocompleteProps } = props;
  const [needle, setNeedle] = useState('');
  const [searchParcel, { data: response, error: requestError, isLoading }] = useLazyReadParcelListQuery();

  const search = useDebounce(
    (needle: string) => {
      const params: PagedRequestParams = {
        offset: 0,
        limit: 10,
        sort: ['id'],
        filters: [{ field: 'id', operator: FilterOperator.like, value: `${needle}%` }],
      };
      searchParcel(params);
    },
    300,
    { leading: false, trailing: true },
  );

  const options = useMemo<string[]>(() => {
    let options: string[] = [];
    if (typeof value === 'string') {
      options = [value as string];
    } else if (Array.isArray(value)) {
      options = [...value];
    }
    if (needle.length >= 1 && response?.data) {
      options = [...options, ...response.data.map(({ id }) => id.toString())];
    }
    return options;
  }, [needle, response, value]);

  const handleInputChange = useCallback(
    (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
      setNeedle(value);
      if (reason === 'input') {
        search(value);
      }
    },
    [search],
  );

  return (
    <Autocomplete
      autoComplete
      value={value}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          inputRef={ref}
          error={Boolean(requestError) || error}
          helperText={(requestError && 'message' in requestError && requestError.message) || helperText || undefined}
          required={required}
          sx={sx}
        />
      )}
      loading={isLoading}
      {...autocompleteProps}
    />
  );
}

export const ParcelAutocomplete = React.memo(React.forwardRef(ParcelAutocompleteComponent));
