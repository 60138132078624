import React, { useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface GenericSelectProps extends Omit<TextFieldProps, 'value' | 'onChange' | 'select'> {
  value: number | null;
  onChange: (value: number | null) => void;
  label: string;
  list?: {
    id: number;
    name: string;
  }[];
}

function GenericSelectComponent(props: GenericSelectProps, ref: React.Ref<unknown>): JSX.Element {
  const { value, onChange, label, list, SelectProps, ...textFieldProps } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = parseInt(event.target.value);
      onChange(isNaN(id) ? null : id);
    },
    [onChange],
  );

  return (
    <TextField
      value={value && list?.length ? value : ''}
      label={label}
      onChange={handleChange}
      select
      SelectProps={{ ref, ...SelectProps }}
      {...textFieldProps}
    >
      {list?.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      )) ?? <MenuItem></MenuItem>}
    </TextField>
  );
}

export const GenericSelect = React.forwardRef(GenericSelectComponent);
