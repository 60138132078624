import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { NotFoundPage, UnauthorizedPage } from '..';
import { UserRole } from '../../entities/User';
import PaperworkAddPage from './PaperworkAddPage';
import PaperworkDetailPage from './PaperworkDetailPage';
import PaperworkEditPage from './PaperworkEditPage';
import PaperworkListPage from './PaperworkListPage';
import PaperworkReportPage from './PaperworkReportPage';

export default function PaperworkRouter(): JSX.Element {
  return (
    <AuthGuard
      authorizeRole={(r) => r === UserRole.READ_PAPERWORKS || r === UserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Routes>
        <Route index element={<PaperworkListPage />} />
        <Route path="add" element={<PaperworkAddPage />} />
        <Route path="report" element={<PaperworkReportPage />} />
        <Route path="duplicate/:id" element={<PaperworkAddPage />} />
        <Route path=":id">
          <Route index element={<PaperworkDetailPage />} />
          <Route path="edit" element={<PaperworkEditPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthGuard>
  );
}
