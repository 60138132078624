import React from 'react';
import { ParcelIcon, UserSectionIcon, PaperworkIcon } from '../components/Icons';
import { AppSection } from '../entities/AppSection';
import { UserRole } from '../entities/User';

export const ParcelSection: AppSection = {
  url: '/parcel',
  title: 'Archivio Regimi Doganali',
  icon: <ParcelIcon />,
  role: UserRole.READ_PARCELS,
};

export const PaperworkSection: AppSection = {
  url: '/paperwork',
  title: 'Archivio Customs',
  icon: <PaperworkIcon />,
  role: UserRole.READ_PAPERWORKS,
};

export const userSection: AppSection = {
  url: '/user',
  title: 'Gestione Utenti',
  icon: <UserSectionIcon />,
  role: UserRole.ADMIN,
  divider: true,
};

const sectionList: AppSection[] = [ParcelSection, PaperworkSection, userSection];

export default sectionList;
