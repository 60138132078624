import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { Home, NotFoundPage, Parcel, UnauthorizedPage, Paperwork, User } from './pages';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props: { error: string }) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="parcel/*" element={<Parcel />} />
      <Route path="paperwork/*" element={<Paperwork />} />
      <Route path="user/*" element={<User />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
