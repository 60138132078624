import { z } from 'zod';

const BaseSchema = z.object({
  pn: z.string().min(1),
  sn: z.string().min(1),
  hts: z.string().min(1),
  weight: z.number().nonnegative(),
  value: z.number().nonnegative(),
  currency: z.string().min(1),
  countervalue: z.number().nonnegative(),
  awb: z.string().optional(),
  awbDate: z.string().optional(),
});

const BaseTISchema = BaseSchema.extend({
  dutiesCountervalue: z.number().nonnegative(),
  customsDutiesCountervalue: z.number().nonnegative(),
  incoterms: z.string().optional(),
  exAReg3: z.string().optional(),
  exAReg3Date: z.string().optional(),
  mrn: z.string().optional(),
  mrnDate: z.string().optional(),
  customsClearance: z.string().optional(),
});

const BaseTESchema = BaseSchema.extend({
  dutiesCountervalue: z.number().nonnegative().optional(),
  customsDutiesCountervalue: z.number().nonnegative().optional(),
  boxNumber: z.string().optional(),
  billImAReg6: z.string().optional(),
  billImAReg6Date: z.string().optional(),
  purchaseOrder: z.string().optional(),
});

export const FullTISchema = BaseTISchema.extend({
  awb: z.string().min(1),
  awbDate: z.string().min(1),
  incoterms: z.string().min(1),
  exAReg3: z.string().min(1),
  exAReg3Date: z.string().min(1),
  mrn: z.string().min(1),
  mrnDate: z.string().min(1),
  customsClearance: z.string().min(1),
});

export const FullTESchema = BaseTESchema.extend({
  awb: z.string().min(1),
  awbDate: z.string().min(1),
  dutiesCountervalue: z.number().nonnegative(),
  customsDutiesCountervalue: z.number().nonnegative(),
  boxNumber: z.string().min(1),
  billImAReg6: z.string().min(1),
  billImAReg6Date: z.string().min(1),
  purchaseOrder: z.string().min(1),
});

export const ItemSchema = BaseTISchema.merge(BaseTESchema);
export type Item = z.infer<typeof ItemSchema>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function validateAwbFields(data: any, ctx: z.RefinementCtx) {
  if (data.awb && !data.awbDate) {
    ctx.addIssue({
      path: ['awbDate'],
      code: z.ZodIssueCode.invalid_type,
      expected: z.ZodParsedType.date,
      received: z.ZodParsedType.null,
    });
  }
  if (data.awbDate && !data.awb) {
    ctx.addIssue({
      path: ['awb'],
      code: z.ZodIssueCode.invalid_type,
      expected: z.ZodParsedType.date,
      received: z.ZodParsedType.null,
    });
  }
  if (data.customsClearance && !data.mrn) {
    ctx.addIssue({
      path: ['customsClearance'],
      code: z.ZodIssueCode.custom,
      message: 'Campo compilabile solo se MRN è valorizzato',
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateUniquePnSn(data: any, ctx: z.RefinementCtx, otherItems: Item[]) {
  if (data.sn && data.pn && otherItems.find(({ pn, sn }) => pn === data.pn && sn === data.sn)) {
    ctx.addIssue({
      path: ['sn'],
      code: z.ZodIssueCode.custom,
      message: 'S/N già presente nella pratica',
    });
  }
}

export const TIFormSchema = BaseTISchema.extend({
  awbDate: z.date().nullable(),
  exAReg3Date: z.date().nullable(),
  mrnDate: z.date().nullable(),
})
  .superRefine(validateAwbFields)
  .superRefine((data, ctx) => {
    if (data.exAReg3 && !data.exAReg3Date) {
      ctx.addIssue({
        path: ['exAReg3Date'],
        code: z.ZodIssueCode.invalid_type,
        expected: z.ZodParsedType.date,
        received: z.ZodParsedType.null,
      });
    }
    if (data.exAReg3Date && !data.exAReg3) {
      ctx.addIssue({
        path: ['exAReg3'],
        code: z.ZodIssueCode.invalid_type,
        expected: z.ZodParsedType.date,
        received: z.ZodParsedType.null,
      });
    }
    if (data.mrn && !data.mrnDate) {
      ctx.addIssue({
        path: ['mrnDate'],
        code: z.ZodIssueCode.invalid_type,
        expected: z.ZodParsedType.date,
        received: z.ZodParsedType.null,
      });
    }
  });

export type TIForm = z.infer<typeof TIFormSchema>;

export const TEFormSchema = BaseTESchema.extend({
  dutiesCountervalue: z.number().nonnegative().nullable(),
  customsDutiesCountervalue: z.number().nonnegative().nullable(),
  awbDate: z.date().nullable(),
  billImAReg6Date: z.date().nullable(),
})
  .superRefine(validateAwbFields)
  .superRefine((data, ctx) => {
    if (data.billImAReg6 && !data.billImAReg6Date) {
      ctx.addIssue({
        path: ['billImAReg6Date'],
        code: z.ZodIssueCode.invalid_type,
        expected: z.ZodParsedType.date,
        received: z.ZodParsedType.null,
      });
    }
    if (data.billImAReg6Date && !data.billImAReg6) {
      ctx.addIssue({
        path: ['billImAReg6'],
        code: z.ZodIssueCode.invalid_type,
        expected: z.ZodParsedType.date,
        received: z.ZodParsedType.null,
      });
    }
  });

export type TEForm = z.infer<typeof TEFormSchema>;

const baseEmptyForm = {
  pn: '',
  sn: '',
  hts: '',
  weight: null,
  value: null,
  currency: null,
  countervalue: null,
  dutiesCountervalue: null,
  customsDutiesCountervalue: null,
  awb: '',
  awbDate: null,
};

export const emptyTI: TIForm = {
  ...baseEmptyForm,
  incoterms: '',
  exAReg3: '',
  exAReg3Date: null,
  mrn: '',
  mrnDate: null,
  customsClearance: '',
} as unknown as TIForm;

export const emptyTE: TEForm = {
  ...baseEmptyForm,
  boxNumber: '',
  billImAReg6: '',
  billImAReg6Date: null,
  purchaseOrder: '',
} as unknown as TEForm;
