import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { Item, FullTESchema, FullTISchema } from '../entities/Item';
import { RegimeEnum } from '../entities/Parcel';
import { formatStringDate, numberUtils } from '../utils';
import { useCurrencyColDef } from './useCurrencyColDef';

interface UseItemGridColumnsParams {
  regime?: RegimeEnum;
  filterable?: boolean;
  sortable?: boolean;
  highlightMissing?: boolean;
}

export function useItemGridColumns(props: UseItemGridColumnsParams): GridColDef<Item>[] {
  const { regime, filterable, sortable, highlightMissing } = props;

  const currencyCol = useCurrencyColDef();

  function getMissingDataClassName({ value }: GridCellParams): string {
    return !value && highlightMissing ? 'missing-data' : '';
  }

  const sharedColumns: GridColDef<Item>[] = [
    {
      field: 'pn',
      headerName: 'P/N',
      filterable,
      sortable,
      width: 120,
      cellClassName: (params: GridCellParams<Item>) => {
        try {
          if (regime === RegimeEnum.TE) {
            FullTESchema.parse(params.row);
          } else {
            FullTISchema.parse(params.row);
          }
          return 'item-complete';
        } catch (error) {
          return '';
        }
      },
    },
    {
      field: 'sn',
      headerName: 'S/N',
      filterable,
      sortable,
      width: 120,
    },
    {
      field: 'hts',
      headerName: 'HTS',
      filterable,
      sortable,
      width: 120,
    },
    {
      field: 'weight',
      headerName: 'Peso Netto',
      filterable,
      sortable,
      type: 'number',
      width: 100,
      valueFormatter: ({ value }: GridValueFormatterParams<Item['weight']>) =>
        numberUtils.format(value, { minimumFractionDigits: 1 }),
    },
    {
      field: 'value',
      headerName: 'Valore',
      filterable,
      sortable,
      type: 'number',
      width: 100,
      valueFormatter: ({ value }: GridValueFormatterParams<Item['value']>) => numberUtils.format(value),
    },
    {
      ...currencyCol,
      filterable,
      sortable,
      width: 70,
    },
    {
      field: 'countervalue',
      headerName: 'Controvalore (€)',
      filterable,
      sortable,
      type: 'number',
      width: 130,
      valueFormatter: ({ value }: GridValueFormatterParams<Item['countervalue']>) => numberUtils.format(value),
    },
  ];

  if (regime === RegimeEnum.TI) {
    return [
      ...sharedColumns,
      {
        field: 'customsDutiesCountervalue',
        headerName: 'Diritti Doganali (€)',
        filterable,
        sortable,
        type: 'number',
        width: 140,
        valueFormatter: ({ value }: GridValueFormatterParams<Item['customsDutiesCountervalue']>) =>
          value === undefined ? '' : numberUtils.format(value),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'dutiesCountervalue',
        headerName: 'Dazi (€)',
        filterable,
        sortable,
        type: 'number',
        width: 100,
        valueFormatter: ({ value }: GridValueFormatterParams<Item['dutiesCountervalue']>) =>
          value === undefined ? '' : numberUtils.format(value),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'incoterms',
        headerName: 'Incoterms',
        filterable,
        sortable,
        width: 120,
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'awb',
        headerName: 'AWB',
        filterable,
        sortable,
        width: 120,
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'awbDate',
        headerName: 'Data AWB',
        filterable,
        sortable,
        type: 'date',
        width: 100,
        valueFormatter: ({ value }) => (typeof value === 'string' ? formatStringDate(value) : undefined),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'exAReg3',
        headerName: 'C.I. / Fattura',
        filterable,
        sortable,
        width: 120,
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'exAReg3Date',
        headerName: 'Data C.I. / Fattura',
        filterable,
        sortable,
        type: 'date',
        width: 140,
        valueFormatter: ({ value }) => (typeof value === 'string' ? formatStringDate(value) : undefined),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'mrn',
        headerName: 'MRN',
        filterable,
        sortable,
        width: 180,
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'mrnDate',
        headerName: 'Data MRN',
        filterable,
        sortable,
        type: 'date',
        width: 100,
        valueFormatter: ({ value }) => (typeof value === 'string' ? formatStringDate(value) : undefined),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'customsClearance',
        headerName: 'Visto Uscire',
        filterable,
        sortable,
        width: 180,
        cellClassName: getMissingDataClassName,
      },
    ];
  } else {
    return [
      ...sharedColumns,
      {
        field: 'boxNumber',
        headerName: 'Numero Box',
        filterable,
        sortable,
        cellClassName: getMissingDataClassName,
        width: 120,
      },
      {
        field: 'awb',
        headerName: 'AWB',
        filterable,
        sortable,
        cellClassName: getMissingDataClassName,
        width: 120,
      },
      {
        field: 'awbDate',
        headerName: 'Data AWB',
        filterable,
        sortable,
        type: 'date',
        width: 100,
        valueFormatter: ({ value }) => (typeof value === 'string' ? formatStringDate(value) : undefined),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'billImAReg6',
        headerName: 'Bolla IM A Reg. 6',
        filterable,
        sortable,
        width: 130,
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'billImAReg6Date',
        headerName: 'Data Bolla IM A Reg. 6',
        filterable,
        sortable,
        type: 'date',
        width: 160,
        valueFormatter: ({ value }) => (typeof value === 'string' ? formatStringDate(value) : undefined),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'purchaseOrder',
        headerName: 'P.O.',
        filterable,
        sortable,
        width: 120,
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'customsDutiesCountervalue',
        headerName: 'Diritti Doganali (€)',
        filterable,
        sortable,
        type: 'number',
        width: 140,
        valueFormatter: ({ value }: GridValueFormatterParams<Item['customsDutiesCountervalue']>) =>
          value === undefined ? '' : numberUtils.format(value),
        cellClassName: getMissingDataClassName,
      },
      {
        field: 'dutiesCountervalue',
        headerName: 'Dazi (€)',
        filterable,
        sortable,
        type: 'number',
        width: 100,
        valueFormatter: ({ value }: GridValueFormatterParams<Item['dutiesCountervalue']>) =>
          value === undefined ? '' : numberUtils.format(value),
        cellClassName: getMissingDataClassName,
      },
    ];
  }
}
