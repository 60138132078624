import React from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FileIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import { useReadPaperworkQuery } from '../../services/paperworkApi';
import { useReadPaperworkStatusListQuery } from '../../services/paperworkStatusApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';

import { formatStringDate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { PaperworkSection } from '../sections';
import { ParcelLinkButton } from './ParcelLinkButton';

interface PaperworkDetailItemProps {
  label: string;
  value?: string | React.ReactNode;
}

export function PaperworkDetailItem(props: PaperworkDetailItemProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex', height: 28, alignItems: 'center' }}>
      <Typography variant="body1" component="span" sx={{ fontWeight: 700 }}>
        {props.label}:
      </Typography>
      <Typography variant="body1" ml={1} whiteSpace="nowrap">
        {props.value}
      </Typography>
    </Box>
  );
}

const breadcrumbs = [{ title: PaperworkSection.title, url: PaperworkSection.url }];

export default function PaperworkDetailPage(): JSX.Element {
  const params = useParams<'id'>();
  const navigate = useNavigate();
  const { isAdmin, hasRole } = useAuth();
  const paperworkId = params.id ? decodeURIComponent(params.id) : '';
  const { data: paperwork, isFetching: loading, error } = useReadPaperworkQuery(paperworkId);

  const { data: statusData } = useReadPaperworkStatusListQuery();
  const { data: typeData } = useReadPaperworkTypeListQuery();

  // READ_PAPERWORKS is required to link parcels to paperworks
  const userCanEditPaperworks = isAdmin || (hasRole(UserRole.WRITE_PAPERWORKS) && hasRole(UserRole.READ_PARCELS));

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        maxWidth={false}
        breadcrumbs={[...breadcrumbs, { title: paperworkId }]}
        disableGutters
        sx={{ p: 1 }}
        error={error}
      >
        <Card>
          {loading && (
            <CardContent>
              <Skeleton sx={{ minWidth: 150, width: '30%', height: 80 }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
            </CardContent>
          )}
          {paperwork && !loading && (
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" gap={1} mb={2}>
                <Typography variant="h4">Dettaglio Pratica {paperworkId}</Typography>
                {userCanEditPaperworks && (
                  <Button variant="outlined" onClick={() => navigate('./edit')}>
                    Modifica
                  </Button>
                )}
              </Stack>
              <PaperworkDetailItem label={'Nome Richiedente'} value={paperwork.applicant} />
              <PaperworkDetailItem label={'Data Richiesta'} value={formatStringDate(paperwork.requestDate)} />
              <PaperworkDetailItem label={'Utente Creazione Pratica'} value={paperwork.createUser} />
              <PaperworkDetailItem
                label={'Data Creazione Pratica'}
                value={formatStringDate(paperwork.createDatetime)}
              />
              <PaperworkDetailItem label={'Tipologia Pratica'} value={typeData?.map[paperwork.type]?.name} />
              {paperwork.license && <PaperworkDetailItem label={'Riferimento Licenza'} value={paperwork.license} />}
              {paperwork.parcel && (
                <PaperworkDetailItem
                  label="Riferimento Pratica"
                  value={<ParcelLinkButton parcel={paperwork.parcel} />}
                />
              )}
              <PaperworkDetailItem label={'Stato Richiesta'} value={statusData?.map[paperwork.status].name} />
              <PaperworkDetailItem label={'Protocollo MT'} value={paperwork.mtReferenceNo} />
              <PaperworkDetailItem label={'Data Protocollo MT'} value={formatStringDate(paperwork.mtReferenceNoDate)} />
              <PaperworkDetailItem
                label={'Protocollo Provvedimento Dogana'}
                value={paperwork.customsProvisionNo ?? '—'}
              />
              <PaperworkDetailItem
                label={'Data  Provvedimento Dogana'}
                value={paperwork.customsProvisionNoDate ? formatStringDate(paperwork.customsProvisionNoDate) : '—'}
              />
              <PaperworkDetailItem
                label={'Diritti Doganali'}
                value={paperwork.customsDuties !== undefined ? `${paperwork.customsDuties} €` : '—'}
              />
              <PaperworkDetailItem
                label={'Sanzioni'}
                value={paperwork.sanctions !== undefined ? `${paperwork.sanctions} €` : '—'}
              />
              <Box my={1}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {'Note'}
                </Typography>
                <Typography variant="body2" mt={1}>
                  {paperwork.note ?? '—'}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {'Allegati'}
                </Typography>
                <Box sx={{ display: 'flex' }} mt={1}>
                  {(paperwork.attachments ?? []).map((a) => (
                    <Chip
                      key={a.id}
                      label={a.name}
                      sx={{ marginRight: 1 }}
                      icon={<FileIcon />}
                      onClick={() => window.open(a.path, '_blank')?.focus()}
                    />
                  ))}
                </Box>
              </Box>
            </CardContent>
          )}
        </Card>
      </Layout>
    </AuthGuard>
  );
}
