import { PagedResponse } from '@top-solution/microtecnica-utils';
import { Status, StatusSchema } from '../entities/Status';
import { api } from './baseApi';

const url = 'v1/statuses';

const statusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readStatusList: builder.query<{ list: Status[]; map: Record<string, Status> }, void>({
      query: () => url,
      transformResponse: (data: PagedResponse<Status>) => {
        const list = StatusSchema.array().parse(data.data);
        const map: Record<string, Status> = {};
        list.forEach((status) => (map[status.id] = status));
        return { list, map };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadStatusListQuery } = statusApi;
