import React, { useState, useCallback, useMemo } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { ConfirmDialog, usePagination } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth, useReadCountriesQuery, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { isFuture } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { green } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGridPremium } from '@mui/x-data-grid-premium';

import { ConfirmAssigneeDialog } from '../../components/Dialogs/ConfirmAssigneeDialog';
import ExpirationFormatted from '../../components/ExpirationFormatted';
import { FileIcon, AlertIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import PaperworkTable from '../../components/PaperworkTable';
import { FullTESchema, FullTISchema } from '../../entities/Item';
import { Parcel, RegimeEnum } from '../../entities/Parcel';
import { StatusEnum } from '../../entities/Status';
import { UserRole } from '../../entities/User';
import { useItemGridColumns } from '../../hooks/useItemGridColumns';
import { useParcelStatus } from '../../hooks/useParcelStatus';
import { useReadParcelQuery, useAssignParcelMutation, useCloseParcelMutation } from '../../services/parcelApi';
import { useReadSaleTypeListQuery } from '../../services/saleTypeApi';
import { useReadShipmentReasonListQuery } from '../../services/shipmentReasonApi';
import { useReadStatusListQuery } from '../../services/statusApi';
import { formatStringDate, parseISODate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { ParcelSection } from '../sections';

interface ParcelDetailItemProps {
  label: string;
  value?: string | React.ReactNode;
}

export function ParcelDetailItem(props: ParcelDetailItemProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {props.label}:
      </Typography>
      <Typography variant="body1" ml={1} whiteSpace="nowrap">
        {props.value}
      </Typography>
    </Box>
  );
}

const breadcrumbs = [{ title: ParcelSection.title, url: ParcelSection.url }];

export default function ParcelDetailPage(): JSX.Element {
  const params = useParams<'id'>();
  const navigate = useNavigate();
  const { isAdmin, hasRole } = useAuth();
  const parcelId = params.id ? decodeURIComponent(params.id) : '';
  const [parcelIdToAssign, setParcelIdToAssign] = useState<string | null>(null);
  const [parcelToClose, setParcelToClose] = useState<Parcel | null>(null);
  const [close, closeStatus] = useCloseParcelMutation();
  const { getParcelStatus } = useParcelStatus();
  const { paginationModel, setPaginationModel } = usePagination(0);

  const [assign, assignStatus] = useAssignParcelMutation();
  const { data: parcel, isFetching: loading, error } = useReadParcelQuery(parcelId);

  const columns = useItemGridColumns({
    regime: parcel?.regime,
    filterable: true,
    sortable: true,
    highlightMissing: true,
  });

  const { data: saleTypeData } = useReadSaleTypeListQuery();
  const { data: shipmentReasonData } = useReadShipmentReasonListQuery();
  const { data: statusData } = useReadStatusListQuery();
  const { data: plantData } = useReadPlantsQuery();
  const { data: countryData } = useReadCountriesQuery();

  const handleAssignParcelConfirm = useCallback(
    async (note: string) => {
      if (parcelIdToAssign) {
        await assign({ id: parcelIdToAssign, note }).unwrap();
        setParcelIdToAssign(null);
      }
    },
    [assign, parcelIdToAssign],
  );

  const handleCloseParcel = useCallback(async () => {
    if (parcelToClose) {
      await close({ id: parcelToClose.id.toString() }).unwrap();
      setParcelToClose(null);
    }
  }, [close, parcelToClose]);

  const isClosable = useMemo(() => {
    try {
      if (parcel) {
        parcel.items.forEach((i) => {
          if (parcel.regime === RegimeEnum.TE) {
            FullTESchema.parse(i);
          } else {
            FullTISchema.parse(i);
          }
        });
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }, [parcel]);

  const deadlineIsFuture = parcel && isFuture(parseISODate(parcel.deadlineDate) as Date);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        maxWidth={false}
        breadcrumbs={[...breadcrumbs, { title: parcelId }]}
        disableGutters
        sx={{ p: 1 }}
        error={error}
      >
        <Card>
          {loading && (
            <CardContent>
              <Skeleton sx={{ minWidth: 150, width: '30%', height: 80 }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '30%' }} />
              <Skeleton sx={{ minWidth: 150, width: '100%', height: 300 }} />
            </CardContent>
          )}
          {parcel && !loading && (
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" gap={1} mb={2}>
                <Typography variant="h4">Dettaglio pratica {parcelId}</Typography>
                {(isAdmin || hasRole(UserRole.WRITE_PARCELS)) && (
                  <div>
                    <Stack direction="row" gap={1}>
                      {getParcelStatus(parcel) !== StatusEnum.Close && (
                        <>
                          <Tooltip
                            title={
                              parcel.assignUser
                                ? `Già presa in carico da ${parcel.assignUser}`
                                : deadlineIsFuture
                                ? 'La pratica non è ancora scaduta'
                                : ''
                            }
                          >
                            <span>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setParcelIdToAssign(parcelId);
                                }}
                                disabled={Boolean(parcel.assignUser) || deadlineIsFuture}
                              >
                                {'Prendi in Carico'}
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip title={isClosable ? '' : 'I dati di alcuni item sono incompleti'}>
                            <span>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setParcelToClose(parcel);
                                }}
                                startIcon={!isClosable && <AlertIcon sx={{ color: 'error.main', opacity: 0.5 }} />}
                                disabled={!isClosable}
                              >
                                {'Chiudi'}
                              </Button>
                            </span>
                          </Tooltip>
                        </>
                      )}
                      <Button variant="outlined" onClick={() => navigate('./edit')}>
                        Modifica
                      </Button>
                    </Stack>
                  </div>
                )}
              </Stack>
              {parcelIdToAssign && (
                <ConfirmAssigneeDialog
                  title={`Confermi presa in carico pratica?`}
                  description="La presa in carico della pratica bloccherà l'invio delle email di notifica."
                  open={Boolean(parcelIdToAssign)}
                  inProgress={assignStatus.isLoading}
                  error={assignStatus.error}
                  onConfirm={handleAssignParcelConfirm}
                  onClose={() => setParcelIdToAssign(null)}
                  confirmButtonText={'Prendi in carico'}
                />
              )}
              {parcelToClose && (
                <ConfirmDialog
                  title={`Confermi chiusura pratica?`}
                  description="La chiusura della pratica bloccherà l'invio delle email di notifica."
                  open={Boolean(parcelToClose)}
                  inProgress={closeStatus.isLoading}
                  error={closeStatus.error}
                  onConfirm={() => handleCloseParcel()}
                  onClose={() => {
                    setParcelToClose(null);
                  }}
                  confirmButtonText={'Chiudi pratica'}
                />
              )}
              <ParcelDetailItem label={'Nome Utente'} value={parcel.createUser} />
              <ParcelDetailItem label={'Data pratica'} value={formatStringDate(parcel.createDatetime)} />
              <Box sx={{ display: 'flex' }}>
                <Typography variant="body1" sx={{ fontWeight: 700 }} mr={1}>
                  {'Data Scadenza'}:
                </Typography>
                <ExpirationFormatted parcel={parcel} typographyOptions={{ variant: 'body1' }} />
              </Box>
              <ParcelDetailItem label={'Stato Pratica'} value={statusData?.map[parcel.status].name} />
              {parcel.assignUser && (
                <>
                  <ParcelDetailItem label={'Utente presa in carico'} value={parcel.assignUser} />
                  <ParcelDetailItem
                    label={'Data presa in carico'}
                    value={formatStringDate(parcel.assignDatetime as string)}
                  />
                </>
              )}
              <ParcelDetailItem label={'Tipologia Pratica'} value={parcel.regime} />
              <ParcelDetailItem label={'Tipologia Vendita'} value={saleTypeData?.map[parcel.saleType].name} />
              {parcel.militaryLicense && (
                <>
                  <ParcelDetailItem label={'Rif. Licenza Militare'} value={parcel.militaryLicense} />
                  <ParcelDetailItem
                    label={'Data rilascio Licenza'}
                    value={formatStringDate(parcel.militaryLicenseIssueDate as string)}
                  />
                  <ParcelDetailItem
                    label={'Data scadenza licenza'}
                    value={formatStringDate(parcel.militaryLicenseExpirationDate as string)}
                  />
                </>
              )}
              <ParcelDetailItem label={'Stabilimento'} value={plantData?.map[parcel.plantId].name} />
              <ParcelDetailItem label={'Protocollo Dogana'} value={parcel.customsReferenceNo} />
              <ParcelDetailItem
                label={'Data Protocollo Dogana'}
                value={formatStringDate(parcel.customsReferenceNoDate)}
              />

              <ParcelDetailItem
                label={'Tipologia Fornitura'}
                value={shipmentReasonData?.map[parcel.shipmentReason].name}
              />
              <ParcelDetailItem label={'Cliente/Fornitore'} value={parcel.customerSupplier} />
              <ParcelDetailItem label={'Paese'} value={countryData?.byISO[parcel.country]?.name || parcel.country} />
              <Box my={1}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {'Note'}
                </Typography>
                <Typography variant="body2" mt={1}>
                  {parcel.note}
                </Typography>
              </Box>
              {parcel.assignUser && (
                <Box my={1}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {'Note presa in carico'}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    {parcel.assignNote}
                  </Typography>
                </Box>
              )}
              <Box mb={2}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {'Allegati'}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
                  {parcel.attachments.map((a) => (
                    <Chip
                      key={a.id}
                      label={a.name}
                      sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                      icon={<FileIcon />}
                      onClick={() => window.open(a.path, '_blank')?.focus()}
                    />
                  ))}
                </Box>
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
                {'Riferimento Archivi Customs'}
              </Typography>
              <PaperworkTable
                paperworks={parcel.paperworks ?? []}
                minimal
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
              <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
                {'Items'}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  '& .missing-data': {
                    backgroundColor: 'rgb(126,10,15,0.1)',
                  },
                  '& .item-complete': {
                    backgroundColor: green[300],
                  },
                }}
              >
                <DataGridPremium
                  density="compact"
                  getRowId={(row) => row.sn + row.pn}
                  rows={parcel.items}
                  columns={columns}
                  disableAggregation
                  disableRowGrouping
                />
              </Box>
            </CardContent>
          )}
        </Card>
      </Layout>
    </AuthGuard>
  );
}
