import {
  PagedRequestParams,
  PagedResponse,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import { Paperwork, PaperworkSchema } from '../entities/Paperwork';
import { TAG_TYPES, api } from './baseApi';

const url = 'v1/paperworks';

const PaperworkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPaperworkList: builder.query<PagedResponse<Paperwork>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(PaperworkSchema).parse(data),
      providesTags: (result) =>
        result ? [TAG_TYPES.PAPERWORK, ...result.data.map(({ id }) => ({ type: TAG_TYPES.PAPERWORK, id }))] : [],
    }),
    readPaperwork: builder.query<Paperwork, string>({
      query: (id) => ({ url: `${url}/${id}` }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (data: any) => PaperworkSchema.parse(data),
      providesTags: (result) => [{ type: TAG_TYPES.PAPERWORK, id: result?.id }],
    }),
    createPaperwork: builder.mutation<
      Paperwork,
      Omit<Paperwork, 'attachments' | 'id' | 'createUser' | 'createDatetime' | 'parcel'> & {
        attachments: number[];
        parcelId?: number;
      }
    >({
      query: (Paperwork) => ({ url, method: 'POST', body: Paperwork }),
      invalidatesTags: () => [TAG_TYPES.PAPERWORK],
    }),
    updatePaperwork: builder.mutation<
      Paperwork,
      Omit<Paperwork, 'attachments' | 'createUser' | 'createDatetime' | 'parcel'> & {
        attachments: number[];
        parcelId?: number;
      }
    >({
      query: (Paperwork) => ({ url: `${url}/${Paperwork.id}`, method: 'PUT', body: Paperwork }),
      invalidatesTags: (result) =>
        result
          ? [TAG_TYPES.PAPERWORK, { type: TAG_TYPES.PAPERWORK, id: result.id }].concat(
              result.parcel ? { type: TAG_TYPES.PARCEL, id: result.parcel.id } : [],
            )
          : [],
    }),
    removePaperwork: builder.mutation<void, Paperwork>({
      query: (Paperwork) => ({ url: `${url}/${Paperwork.id}`, method: 'DELETE' }),
      invalidatesTags: (result, error, arg) =>
        arg ? [TAG_TYPES.PAPERWORK, { type: TAG_TYPES.PAPERWORK, id: arg.id }] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadPaperworkListQuery,
  useReadPaperworkQuery,
  useCreatePaperworkMutation,
  useRemovePaperworkMutation,
  useUpdatePaperworkMutation,
} = PaperworkApi;
