import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function FormRow(props: BoxProps) {
  const { children, sx, ...boxProps } = props;
  return (
    <Box
      className="FormRow-root"
      sx={{ display: 'flex', mx: -1, '& > div': { mx: 1 }, '& + .FormRow-root': { marginTop: 2 }, ...sx }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

export default React.memo(FormRow);
