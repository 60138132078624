import { PagedResponse } from '@top-solution/microtecnica-utils';
import { Currency, CurrencySchema } from '../entities/Currency';
import { api } from './baseApi';

const url = 'v1/currencies';

const currencyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCurrencyList: builder.query<{ list: Currency[]; map: Record<string, Currency> }, void>({
      query: () => url,
      transformResponse: (data: PagedResponse<Currency>) => {
        const list = CurrencySchema.array().parse(data.data);
        const map: Record<string, Currency> = {};
        list.forEach((type) => (map[type.code] = type));
        return { list, map };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadCurrencyListQuery } = currencyApi;
