import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { ParcelAddForm } from '../../entities/Parcel';
import { StatusEnum } from '../../entities/Status';
import { UserRole } from '../../entities/User';
import { useParcelSchemaWIthMilitaryCheck } from '../../hooks/useParcelSchemaWIthMilitaryCheck';
import { useCreateParcelMutation } from '../../services/parcelApi';
import { useReadStatusListQuery } from '../../services/statusApi';
import { formatISODate } from '../../utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { ParcelSection } from '../sections';
import { ParcelEditCard } from './ParcelEditCard';

const title = 'Nuova pratica';
const breadcrumbs = [{ title: ParcelSection.title, url: ParcelSection.url }, { title }];

const defaultValues: ParcelAddForm = {
  attachments: [],
  customerSupplier: '',
  country: null,
  customsReferenceNo: '',
  customsReferenceNoDate: null,
  deadlineDate: null,
  militaryLicense: '',
  militaryLicenseExpirationDate: null,
  militaryLicenseIssueDate: null,
  note: '',
  plantId: null,
  regime: '',
  status: null,
  saleType: null,
  shipmentReason: null,
  items: [],
} as unknown as ParcelAddForm;

export default function ParcelAddPage(): JSX.Element {
  const navigate = useNavigate();
  const [create, createStatus] = useCreateParcelMutation();
  const { ParcelAddFormSchemaWithMilitaryCheck, isLoading: parcelAddFormSchemaIsLoading } =
    useParcelSchemaWIthMilitaryCheck();

  const statusList = useReadStatusListQuery();

  const defaultValuesWithOpenStatus = useMemo(() => {
    const openStatusValue = statusList.data?.list.find((s) => s.name === StatusEnum.Open)?.id;
    return { ...defaultValues, status: openStatusValue } as ParcelAddForm;
  }, [statusList.data?.list]);

  const handleSubmit = useCallback(
    async (data: ParcelAddForm) => {
      await create({
        ...data,
        attachments: data.attachments.map((a) => a.id),
        customsReferenceNoDate: formatISODate(data.customsReferenceNoDate),
        deadlineDate: formatISODate(data.deadlineDate),
        militaryLicenseExpirationDate: data.militaryLicenseExpirationDate
          ? formatISODate(data.militaryLicenseExpirationDate)
          : undefined,
        militaryLicenseIssueDate: data.militaryLicenseIssueDate
          ? formatISODate(data.militaryLicenseIssueDate)
          : undefined,
      }).unwrap();
      navigate('..');
    },
    [create, navigate],
  );

  return (
    <AuthGuard
      authorizeRole={(role) => role === UserRole.WRITE_PARCELS || role === UserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Layout
        breadcrumbs={breadcrumbs}
        disableGutters
        sx={{ p: 1 }}
        title={title}
        error={statusList.error}
        inProgress={statusList.isLoading || parcelAddFormSchemaIsLoading}
      >
        <ParcelEditCard
          title={title}
          resolver={ParcelAddFormSchemaWithMilitaryCheck}
          defaultValues={defaultValuesWithOpenStatus}
          isLoading={createStatus.isLoading}
          error={createStatus.error}
          onSubmit={handleSubmit}
          editing={false}
        />
      </Layout>
    </AuthGuard>
  );
}
