import React, { useCallback, useState } from 'react';
import { DataGridWrapper, DeleteConfirmDialog, usePagination } from '@top-solution/microtecnica-mui';
import { AuthGuard, PagedRequestParams } from '@top-solution/microtecnica-utils';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { Layout } from '../../components/Layout';
import PaperworkTable from '../../components/PaperworkTable';
import { Paperwork } from '../../entities/Paperwork';
import { useReadPaperworkListQuery, useRemovePaperworkMutation } from '../../services/paperworkApi';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { PaperworkSection } from '../sections';

const breadcrumbs = [{ title: PaperworkSection.title }];

export default function PaperworkListPage(): JSX.Element {
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [paperworkToRemove, setPaperworkToRemove] = useState<Paperwork | null>(null);
  const [removePaperwork, removePaperworkRequest] = useRemovePaperworkMutation();
  const [queryParams, setQueryParams] = useState<PagedRequestParams>({
    limit: paginationModel.pageSize as number,
    offset: paginationModel.pageSize ? paginationModel.pageSize * paginationModel.page : 0,
    sort: ['id'],
    filters: [],
  });
  const PaperworkList = useReadPaperworkListQuery(queryParams, {
    skip: queryParams.limit === undefined,
  });

  const handleRemovePaperworkConfirm = useCallback(async () => {
    if (paperworkToRemove) {
      await removePaperwork(paperworkToRemove).unwrap();
      setPaperworkToRemove(null);
    }
  }, [paperworkToRemove, removePaperwork]);

  const handleDeleteClick = useCallback(({ row }: GridRowParams) => {
    setPaperworkToRemove(row as Paperwork);
  }, []);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }} error={PaperworkList.error}>
        <DataGridWrapper>
          <PaperworkTable
            paperworks={PaperworkList.data?.data ?? []}
            onDelete={handleDeleteClick}
            onParamsChange={setQueryParams}
            minimal={false}
            total={PaperworkList.data?.total ?? 0}
            loading={PaperworkList.isFetching}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </DataGridWrapper>
        {paperworkToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare la pratica nr° "${paperworkToRemove.id}"?`}
            confirmText="confermo"
            open={Boolean(paperworkToRemove)}
            inProgress={removePaperworkRequest.isLoading}
            error={removePaperworkRequest.error}
            onConfirm={() => handleRemovePaperworkConfirm()}
            onClose={() => {
              removePaperworkRequest.reset();
              setPaperworkToRemove(null);
            }}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
