import { z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { ItemSchema } from './Item';

export enum RegimeEnum {
  TI = 'TI',
  TE = 'TE',
}

const ParcelPaperworkSchema = z.object({
  applicant: z.string().min(1, 'Campo obbligatorio'),
  requestDate: z.string().min(1, 'Campo obbligatorio'),
  createDatetime: z.string(),
  createUser: z.string(),
  mtReferenceNo: z.string().min(1, 'Campo obbligatorio'),
  mtReferenceNoDate: z.string().min(1, 'Campo obbligatorio'),
  customsProvisionNo: z.string().optional().nullable(),
  customsProvisionNoDate: z.string().optional().nullable(),
  id: z.number(),
  note: z.string().max(512).optional(),
  customsDuties: z.number().optional().nullable(),
  sanctions: z.number().optional().nullable(),
  license: z.string().optional(),
  type: z.number(),
  status: z.number(),
});

export type ParcelPaperwork = z.infer<typeof ParcelPaperworkSchema>;

export const ParcelSchema = z.object({
  assignDatetime: z.string().optional(),
  assignNote: z.string().optional(),
  assignUser: z.string().optional(),
  attachments: z.array(AttachmentSchema).min(1, 'Campo obbligatorio'),
  customerSupplier: z.string().min(1, 'Campo obbligatorio'),
  country: z.string(),
  createDatetime: z.string(),
  createUser: z.string(),
  customsReferenceNo: z.string().min(1, 'Campo obbligatorio'),
  customsReferenceNoDate: z.string().min(1, 'Campo obbligatorio'),
  deadlineDate: z.string().min(1, 'Campo obbligatorio'),
  id: z.number(),
  items: z.array(ItemSchema).min(1, 'Inserire almeno 1 item'),
  militaryLicense: z.string().optional(),
  militaryLicenseExpirationDate: z.string().optional(),
  militaryLicenseIssueDate: z.string().optional(),
  note: z.string().max(512).optional(),
  plantId: z.number(),
  regime: z.nativeEnum(RegimeEnum, {
    errorMap: () => ({ message: 'Campo obbligatorio' }),
  }),
  saleType: z.number(),
  shipmentReason: z.number(),
  status: z.number(),
  paperworks: z.array(ParcelPaperworkSchema).optional().nullable(),
});

export type Parcel = z.infer<typeof ParcelSchema>;

export const ParcelListSchema = ParcelSchema.omit({
  paperworks: true,
}).extend({
  paperworks: z
    .array(ParcelPaperworkSchema.pick({ id: true, status: true }))
    .optional()
    .nullable(),
});

export type ParcelList = z.infer<typeof ParcelListSchema>;

export const ParcelAddFormSchema = ParcelSchema.omit({
  id: true,
  createUser: true,
  createDatetime: true,
  assignUser: true,
  assignDatetime: true,
  paperworks: true,
}).extend({
  customsReferenceNoDate: z.date(),
  deadlineDate: z.date(),
  militaryLicenseExpirationDate: z.date().nullable(),
  militaryLicenseIssueDate: z.date().nullable(),
});

export type ParcelAddForm = z.infer<typeof ParcelAddFormSchema>;
