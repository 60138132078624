import { z } from 'zod';

export const AttachmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
  createUser: z.string(),
  createDatetime: z.string(),
});

export type Attachment = z.infer<typeof AttachmentSchema>;
