import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';
import sectionList from '../sections';

export default function HomePage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();

  const availableSections = useMemo(
    () => sectionList.filter(({ role }) => isAdmin || (role && hasRole(role))),
    [hasRole, isAdmin],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth="sm">
        <Paper>
          <List>
            {availableSections.map((section) => (
              <React.Fragment key={section.url}>
                {section.divider ? <Divider /> : null}
                <ListItem disableGutters>
                  <ListItemButton component={Link} to={section.url} disabled={section.disabled}>
                    <ListItemIcon>{section.icon}</ListItemIcon>
                    <ListItemText primary={section.title} />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Layout>
    </AuthGuard>
  );
}
