import { z } from 'zod';

export enum SaleTypeEnum {
  Civil = 'Civile',
  Military = 'Militare',
  DualUse = 'Dual Use',
}

export const SaleTypeSchema = z.object({
  id: z.number(),
  name: z.nativeEnum(SaleTypeEnum),
});

export type SaleType = z.infer<typeof SaleTypeSchema>;
