import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { WideTooltip } from '../../components/WideTooltip';
import { Paperwork } from '../../entities/Paperwork';
import { useReadPaperworkStatusListQuery } from '../../services/paperworkStatusApi';
import { useReadPaperworkTypeListQuery } from '../../services/paperworkTypeApi';
import { formatStringDate } from '../../utils';
import { PaperworkDetailItem } from '../Paperwork/PaperworkDetailPage';

interface PaperworkLinkButtonProps {
  paperwork: Omit<Paperwork, 'attachments' | 'parcel'>;
}

function PaperworkLinkButtonComponent(props: PaperworkLinkButtonProps) {
  const { paperwork } = props;

  const { data: statusData } = useReadPaperworkStatusListQuery();
  const { data: typeData } = useReadPaperworkTypeListQuery();
  return (
    <WideTooltip
      arrow
      title={
        <>
          <PaperworkDetailItem label={'Nome Richiedente'} value={paperwork.applicant} />
          <PaperworkDetailItem label={'Data Richiesta'} value={formatStringDate(paperwork.requestDate)} />
          <PaperworkDetailItem label={'Utente Creazione'} value={paperwork.createUser} />
          <PaperworkDetailItem label={'Data Creazione'} value={formatStringDate(paperwork.createDatetime)} />
          <PaperworkDetailItem label={'Tipologia'} value={typeData?.map[paperwork.type]?.name} />
          {paperwork.license && <PaperworkDetailItem label={'Riferimento Licenza'} value={paperwork.license} />}
          <PaperworkDetailItem label={'Stato Richiesta'} value={statusData?.map[paperwork.status].name} />
          <PaperworkDetailItem label={'Protocollo MT'} value={paperwork.mtReferenceNo} />
          <PaperworkDetailItem label={'Data Protocollo MT'} value={formatStringDate(paperwork.mtReferenceNoDate)} />
          <PaperworkDetailItem label={'Protocollo Provv. Dogana'} value={paperwork.customsProvisionNo ?? '—'} />
          <PaperworkDetailItem
            label={'Data  Provvedimento Dogana'}
            value={paperwork.customsProvisionNoDate ? formatStringDate(paperwork.customsProvisionNoDate) : '—'}
          />
          <PaperworkDetailItem
            label={'Diritti Doganali'}
            value={paperwork.customsDuties ? `${paperwork.customsDuties} €` : '—'}
          />
          <PaperworkDetailItem label={'Sanzioni'} value={paperwork.sanctions ? `${paperwork.sanctions} €` : '—'} />
        </>
      }
      sx={{
        '.MuiTooltip-tooltip': {
          background: 'red',
          maxWidth: 500,
        },
      }}
    >
      <Button
        size="small"
        color="primary"
        variant="outlined"
        component={Link}
        to={`/paperwork/${paperwork.id}`}
        sx={{ mr: 0.5 }}
      >
        {`#${paperwork.id}`}
      </Button>
    </WideTooltip>
  );
}

export const PaperworkLinkButton = React.memo(PaperworkLinkButtonComponent);
