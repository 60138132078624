import { PagedResponse } from '@top-solution/microtecnica-utils';
import { ShipmentReason, ShipmentReasonSchema } from '../entities/ShipmentReason';
import { api } from './baseApi';

const url = 'v1/shipment-reasons';

const shipmentReasonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readShipmentReasonList: builder.query<{ list: ShipmentReason[]; map: Record<string, ShipmentReason> }, void>({
      query: () => url,
      transformResponse: (data: PagedResponse<ShipmentReason>) => {
        const list = ShipmentReasonSchema.array().parse(data.data);
        const map: Record<string, ShipmentReason> = {};
        list.forEach((type) => (map[type.id] = type));
        return { list, map };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadShipmentReasonListQuery } = shipmentReasonApi;
