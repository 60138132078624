import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type FakeInputProps = BoxProps & {
  label: string;
  error: boolean;
  helperText?: string;
};

function FakeInput(props: FakeInputProps) {
  const { label, children, sx, error, helperText, ...boxProps } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...boxProps}
    >
      <Box
        className="FakeInput-root"
        sx={{
          flex: 1,
          border: 1,
          borderColor: error ? 'error.main' : 'grey.400',
          borderRadius: 1,
          mb: 2.75,
          px: 1.75,
          position: 'relative',
        }}
      >
        <Typography
          className="FakeInput-label"
          variant="caption"
          sx={{
            position: 'absolute',
            top: -10,
            left: 8,
            color: 'text.secondary',
            px: '5px',
            bgcolor: 'background.paper',
          }}
        >
          {label}
        </Typography>
        {children}
      </Box>
      {error && (
        <Typography
          className="FakeInput-error"
          variant="caption"
          mt={1}
          mx={2}
          sx={{
            color: 'error.main',
            position: 'absolute',
            bottom: 0,
          }}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default React.memo(FakeInput);
